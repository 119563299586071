import { FeatureConfigSelectors } from '@common/data-access-feature-config';
import {
  applianceIsHeating,
  applianceIsWhiteGoods,
  isWithinMonthsFromCurrentYearMonth,
} from '@common/util-foundation';
import {
  AvailableCover,
  BasketItem,
  BasketItemApplianceDetails,
  CheckoutBasket,
  ContractType,
  Cover,
  CoverType,
  ExcessPrice,
  Quote,
  QuotesConfig,
  SelectedBasketItem,
  SelectedBasketItemApplianceQuote,
  TaggingMetadata,
  UpgradeCoverConfig,
} from '@common/util-models';
import { createSelector } from '@ngrx/store';
import { QuotesState } from './quotes.reducer';
import {
  getApplianceDetails,
  getBasketId,
  getCurrentItem,
  getCurrentItemFirstCover,
  getCurrentItemIsHeatingAppliance,
  getFirstCoverTypeFromWhitelist,
  getInitialCoverType,
  getQuoteDetails,
  getQuotesState,
  getReturnedCoverTypes,
  getRouteParamsAutoSelect,
  getSelectedCover,
  getSelectedCoverType,
  getSelectedExcess,
} from './quotes.selectors';
import {
  DISCOUNT_MONTHS,
  generateAvailableExcessPrices,
  generateQuoteFromBasketApplianceDetailsAndCover,
  getCoverDescription,
  selectedItemApplianceQuote,
  selectedItemApplianceQuotes,
  transformQuoteDetailToCover,
} from './quotes.utils';

export const getSelectedContractType = createSelector(
  getSelectedCover,
  (cover) => cover?.contractTypeCode || null
);
export const getIsInsurance = createSelector(
  getSelectedCover,
  (cover) => cover?.contractTypeCode == ContractType.Insurance
);

// TODO: if somebody refactors this talk to Vladyslav Shkurenko or Theo Tonge
export const getTaggingMetadata = createSelector(
  getApplianceDetails,
  getSelectedCover,
  getCurrentItemIsHeatingAppliance,
  (
    applianceDetails: BasketItemApplianceDetails | undefined,
    cover: Cover | undefined,
    isHeating
  ) => {
    if (applianceDetails && cover) {
      return {
        ...applianceDetails,
        schemeCode: cover.schemeCode,
        companyCode: cover.companyCode,
        outOfWarranty: !applianceDetails.inWarranty,
        isHeating,
      } as TaggingMetadata;
    }

    return undefined;
  }
);

export const getQuote = createSelector(
  getCurrentItem,
  getSelectedCover,
  (currentItem?: BasketItem, cover?: Cover): Quote | undefined =>
    generateQuoteFromBasketApplianceDetailsAndCover(
      currentItem?.data?.applianceDetails,
      cover,
      currentItem
    )
);

export const getCurrentItemFirstQuote = createSelector(
  getCurrentItem,
  getCurrentItemFirstCover,
  getApplianceDetails,
  (currentItem?: BasketItem, cover?: Cover): Quote | undefined =>
    generateQuoteFromBasketApplianceDetailsAndCover(
      currentItem?.data?.applianceDetails,
      cover,
      currentItem
    )
);

export const getSelectItemsApplianceQuotes = createSelector(
  getQuotesState,
  (state: QuotesState): SelectedBasketItemApplianceQuote[] =>
    selectedItemApplianceQuotes(state)
);

export const getSelectedItemApplianceQuote = (
  selectedItem: SelectedBasketItem
) =>
  createSelector(getQuotesState, (state: QuotesState):
    | SelectedBasketItemApplianceQuote
    | undefined => selectedItemApplianceQuote(state, selectedItem));

export const isSelectedItemPurchaseDateWithinDiscountMonths = (
  selectedItem: SelectedBasketItem
) =>
  createSelector(
    getSelectedItemApplianceQuote(selectedItem),
    (
      selectedItem: SelectedBasketItemApplianceQuote | undefined
    ): boolean | undefined => {
      const applianceDetails = selectedItem?.applianceDetails;
      return applianceDetails
        ? isWithinMonthsFromCurrentYearMonth(
            applianceDetails.purchaseYear,
            applianceDetails.purchaseMonth,
            DISCOUNT_MONTHS
          )
        : undefined;
    }
  );

export const getMultiItemQuotes = createSelector(
  getQuotesState,
  FeatureConfigSelectors.getQuotesConfig,
  (state: QuotesState, config?: QuotesConfig): Quote[] => {
    return selectedItemApplianceQuotes(state).reduce(
      (quotes: Quote[], item: SelectedBasketItemApplianceQuote) => {
        const name =
          config?.coverTypeNames &&
          config?.coverTypeNames[<CoverType>item.quote?.coverType];

        const cover = {
          name,
          ...transformQuoteDetailToCover(item.quote),
        };

        const quote = generateQuoteFromBasketApplianceDetailsAndCover(
          item.applianceDetails,
          cover,
          item
        );

        if (quote) {
          quotes.push(quote);
        }

        return quotes;
      },
      []
    );
  }
);

export const currentQuoteBasketPosition = createSelector(
  getMultiItemQuotes,
  (multiItemQuotes: Quote[]): number => multiItemQuotes.length + 1
);

export const hasExistingHeatingApplianceQuote = createSelector(
  getMultiItemQuotes,
  (multiItemQuotes: Quote[]): boolean =>
    multiItemQuotes.some((quote: Quote) => applianceIsHeating(quote))
);

export const hasExistingWhiteGoodsApplianceQuote = createSelector(
  getMultiItemQuotes,
  (multiItemQuotes: Quote[]): boolean =>
    multiItemQuotes.some((quote: Quote) => applianceIsWhiteGoods(quote))
);

export const getQuoteByItemId = (props: { id: string }) =>
  createSelector(getMultiItemQuotes, (multiItemQuotes: Quote[]):
    | Quote
    | undefined => multiItemQuotes.find((quote) => quote.itemId === props.id));

/**
 * Get available cover types as strings
 */
export const getAvailableCoverTypes = createSelector(
  FeatureConfigSelectors.getQuotesConfig,
  getReturnedCoverTypes,
  getInitialCoverType,
  (config, returnedCoverTypes, initialCoverType): Array<CoverType> => {
    if (config?.showAllCoverTypes) {
      return returnedCoverTypes;
    }

    //based on config work out what cover types we should offer as upgrades
    const upgradeCoverTypes = (
      config?.coverTypeUpgrades[initialCoverType] || []
    ).map((upgradeCoverType) => upgradeCoverType.coverType);

    //return initial plus upgrades, but remove any that were not returned by API
    return [
      initialCoverType,
      ...upgradeCoverTypes.filter((coverType: CoverType) =>
        returnedCoverTypes.includes(coverType)
      ),
    ];
  }
);

/**
 * Get alternate product types as strings
 */
export const getAlternateProduct = createSelector(
  FeatureConfigSelectors.getQuotesConfig,
  getInitialCoverType,
  (config, initialCoverType): UpgradeCoverConfig | undefined => {
    return config?.alternateProductUpgrades
      ? config.alternateProductUpgrades[initialCoverType]
      : undefined;
  }
);

/**
 * Get available covers with relevant properties i.e. price
 */
export const getAvailableCovers = createSelector(
  getAvailableCoverTypes,
  getQuoteDetails,
  getInitialCoverType,
  getSelectedExcess,
  FeatureConfigSelectors.getQuotesConfig,
  (
    coverTypes,
    quotes,
    initialCoverType,
    selectedExcess,
    config
  ): AvailableCover[] => {
    if (!quotes) {
      return [];
    }
    return quotes
      .filter(
        (quote) =>
          coverTypes.includes(quote.coverType) &&
          quote.excessAmount === selectedExcess
      )
      .map((cover) => {
        const isUpgrade = initialCoverType !== cover.coverType;
        const name =
          config?.coverTypeNames && config.coverTypeNames[cover.coverType];

        const description = getCoverDescription(
          cover.coverType,
          config,
          isUpgrade,
          initialCoverType
        );

        return {
          name,
          description,
          ...transformQuoteDetailToCover(cover),
          isUpgrade,
        };
      })
      .sort((a, b) => {
        if (a.isUpgrade && b.isUpgrade) {
          return 0;
        }
        //todo: support multiple upgrades order via config (whitelist)
        return a.isUpgrade ? 1 : -1;
      });
  }
);

export const getCoverExclusionDisplayType = createSelector(
  FeatureConfigSelectors.getQuotesConfig,
  (config): 'tabs' | 'stacked' | undefined | null => {
    return config?.coverExclusionDisplayType;
  }
);

/**
 * Get available excess values, along with payment methods
 */
export const getAvailableExcessPrices = createSelector(
  getQuoteDetails,
  getSelectedCoverType,
  (quotes, coverType): ExcessPrice[] => {
    return generateAvailableExcessPrices(quotes, coverType);
  }
);

/**
 * Get base and upgrade covers if cover is upgradable
 */
export const getUpgradableCovers = createSelector(
  getAvailableCovers,
  (availableCovers: AvailableCover[]) => {
    if (availableCovers.length !== 2) {
      return undefined;
    }

    return {
      base: availableCovers.find((ac) => !ac.isUpgrade),
      upgrade: availableCovers.find((ac) => !!ac.isUpgrade),
    };
  }
);

export const getExcessToDisplay = createSelector(
  getSelectedExcess,
  getAvailableExcessPrices,
  (selectedExcess, availableExcess) => {
    if (availableExcess.length > 1) {
      return selectedExcess;
    } else {
      return null;
    }
  }
);

export const getCheckoutBasket = createSelector(
  getBasketId,
  getMultiItemQuotes,
  (
    basketId: string | undefined,
    multiItemQuotes: Quote[]
  ): CheckoutBasket | undefined => {
    if (!basketId) return;

    return {
      basketId,
      items: multiItemQuotes.map((item) => {
        return {
          itemId: item.itemId,
          itemType: item.itemType,
          quoteId: item.cover.quoteId,
          paymentOptions: item.cover.paymentOptions,
          contractType: item.cover.contractTypeCode,
          companyCode: item.cover.companyCode,
          schemeCode: item.cover.schemeCode,
        };
      }),
    };
  }
);

export const shouldAutoSelectCover = createSelector(
  getQuotesState,
  hasExistingHeatingApplianceQuote,
  (
    state: QuotesState,
    hasExistingHeatingApplianceQuote: boolean,
    props: { isMultiQuoteFlow: boolean }
  ): boolean => {
    if (hasExistingHeatingApplianceQuote && state.enableAutoSelectCover) {
      return true;
    }
    return !props.isMultiQuoteFlow && !!state.enableAutoSelectCover;
  }
);

export const getDefaultCoverType = createSelector(
  shouldAutoSelectCover,
  getRouteParamsAutoSelect,
  getFirstCoverTypeFromWhitelist,
  (
    shouldAutoSelectCover: boolean,
    paramsFromRoute: { coverType: CoverType } | undefined,
    firstCoverTypeFromWhitelist: CoverType | undefined
  ): CoverType | undefined => {
    if (!shouldAutoSelectCover) {
      return;
    }

    return paramsFromRoute?.coverType ?? firstCoverTypeFromWhitelist;
  }
);
