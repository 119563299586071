import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { toggleExpandedAnimation } from '../animations';
export type ExpandStatus =
  | 'collapsed'
  | 'collapsing'
  | 'expanded'
  | 'expanding';
@Component({
  selector: 'ui-panel-body',
  templateUrl: './panel-body.component.html',
  styleUrls: ['./panel-body.component.scss'],
  animations: [toggleExpandedAnimation],
})
export class PanelBodyComponent {
  @Input() isExpanded = true;
  @Output()
  toggleExpandAnimation: EventEmitter<ExpandStatus> = new EventEmitter();

  onAnimationEvent(event: AnimationEvent) {
    const done = event.phaseName === 'done';
    if (event.fromState === 'expanded' && event.toState === 'collapsed') {
      this.toggleExpandAnimation.emit(done ? 'collapsed' : 'collapsing');
    } else if (
      event.fromState === 'collapsed' &&
      event.toState === 'expanded'
    ) {
      this.toggleExpandAnimation.emit(done ? 'expanded' : 'expanding');
    }
  }
}
