import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { ButtonComponentsModule } from '../button/button-components.module';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';

@NgModule({
  imports: [CommonModule, ButtonComponentsModule, RouterModule],
  declarations: [AlertDialogComponent],
  exports: [MatDialogModule],
})
export class DialogComponentsModule {}
