import { CardComponent } from './card/card.component';
import { CardHeaderComponent } from './card-header/card-header.component';
import { CardBodyComponent } from './card-body/card-body.component';
import { CardTitleComponent } from './card-title/card-title.component';
import { CardRadioComponent } from './card-radio/card-radio.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const components = [
  CardComponent,
  CardHeaderComponent,
  CardBodyComponent,
  CardTitleComponent,
  CardRadioComponent,
];
@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class CardComponentsModule {}
