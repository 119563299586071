<ng-container *ngIf="data && data.key">
  <dt *ngIf="data.key">{{ data.key }}</dt>
  <dd *ngIf="data.value || data.key" [innerHTML]="data.value || '-'"></dd>
</ng-container>
<ng-container *ngIf="data && !data.key">
  <span
    class="just-value"
    *ngIf="data.value || data.key"
    [innerHTML]="data.value || '-'"
  ></span>
</ng-container>
