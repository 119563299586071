<ng-container *ngIf="vm$ | async as vm">
  <div
    class="appliance-details-form"
    [ngClass]="{ 'appliance-details-form--disabled': vm.form?.disabled }"
  >
    <dgx-dfb-form
      (analytics)="handleAnalytics($event)"
      (invalidSubmit)="handleInvalidFormSubmission($event)"
      (validatedSubmit)="handleValidFormSubmission($any($event))"
      [controlsToHide]="vm.formControlsToHide"
      [fieldset]="vm.formBuilderConfig"
      [form]="vm.form"
      [monthYearDateUpdater]="vm.monthYearDateUpdater"
      [validate]="validate"
    ></dgx-dfb-form>

    <ui-alert
      *ngIf="vm.applianceNotWorking"
      class="appliance-details-form__alert"
      data-test-hook="appliance-not-working-alert"
    >
      <dgx-cms-dynamic-component-outlet area="not_good_order">
      </dgx-cms-dynamic-component-outlet>
    </ui-alert>

    <ui-alert
      *ngIf="(vm.inWarranty || vm.inBrandWarranty) && !vm.applianceNotWorking"
      [variant]="'info'"
      [heading]="'Your boiler is still under guarantee'"
      class="appliance-details-form__alert appliance-details-form__alert--guarantee"
      data-test-hook="warranty-alert"
    >
      <p>
        Give us a call so we can offer you a maintenance and support plan that
        suits you:
      </p>
      <p>
        <a class="phone" href="tel:08005614493">0800 561 4493</a><br />
        Choose Option 2 then Option 1
      </p>
      <p>
        We're available: <br />
        8am to 8pm Monday - Friday<br />
        9am to 5pm Saturday - Sunday
      </p>
    </ui-alert>

    <ui-alert
      *ngIf="vm.inExtendedWarranty"
      [variant]="'info'"
      [heading]="
        ' We don\'t currently offer protection for appliances with these guarantee
      lengths.'
      "
      class="appliance-details-form__alert"
      data-test-hook="extended-warranty-alert"
    >
    </ui-alert>
  </div>
</ng-container>
