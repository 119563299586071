import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as CheckoutActions from './checkout.actions';
import { PersonalDetailsPartialState } from '@common/data-access-personal-details';
import { Action, select, Store } from '@ngrx/store';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  CheckoutRequest,
  isPaymentByCreditCard,
  isPaymentByDirectDebitToken,
  PaymentByDirectDebit,
  PaymentType,
} from '@common/util-models';
import { UserProfilePartialState } from '@common/data-access-user-profile';
import { CheckoutApiService } from '../services/checkout-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  EMPTY,
  MonoTypeOperatorFunction,
  of,
  OperatorFunction,
  pipe,
} from 'rxjs';
import { Router } from '@angular/router';
import {
  BuildConfigService,
  ErrorService,
  LoaderService,
} from '@common/util-foundation';
import {
  getGuestUserCheckoutRequest,
  getLoggedInUserCheckoutRequest,
} from './checkout.selectors';
import {
  CardPaymentPartialState,
  CardPaymentSelectors,
} from '@common/data-access-card-payment';
import {
  SelectPaymentPartialState,
  SelectPaymentSelectors,
} from '@common/data-access-select-payment';
import * as CheckoutSelectors from './checkout.selectors';
import { CheckoutPartialState } from './checkout.reducer';
import { FeatureConfigSelectors } from '@common/data-access-feature-config';
import { MarketingTaggingService } from '@common/data-access-shared';
import { CheckoutBasketPartialState } from '@common/data-access-checkout-basket';

@Injectable()
export class CheckoutEffects {
  checkoutGuest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.checkoutGuest),
      withLatestFrom(this.store$.pipe(select(getGuestUserCheckoutRequest))),
      filter(([, checkoutRequest]) => !!checkoutRequest),
      map(([, checkoutRequest]: [Action, CheckoutRequest | undefined]) => {
        return CheckoutActions.startCheckout({
          checkoutRequest: checkoutRequest as CheckoutRequest,
        });
      })
    )
  );

  checkoutLoggedInUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.checkoutLoggedInUser),
      withLatestFrom(this.store$.pipe(select(getLoggedInUserCheckoutRequest))),
      filter(([, checkoutRequest]) => !!checkoutRequest),
      map(([, checkoutRequest]: [Action, CheckoutRequest | undefined]) => {
        return CheckoutActions.startCheckout({
          checkoutRequest: checkoutRequest as CheckoutRequest,
        });
      })
    )
  );

  startCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.startCheckout),
      map(({ checkoutRequest }) => {
        if (isPaymentByCreditCard(checkoutRequest.payment)) {
          return CheckoutActions.checkoutCardPayment({ checkoutRequest });
        }

        if (isPaymentByDirectDebitToken(checkoutRequest.payment)) {
          return CheckoutActions.checkoutDirectDebitPayment({
            checkoutRequest,
          });
        }

        return CheckoutActions.validateDirectDebitDetails({
          checkoutRequest,
          validateDirectDebitRequest: {
            account_number: (checkoutRequest.payment as PaymentByDirectDebit)
              .directDebitDetails.accountNumber,
            branch_code: (checkoutRequest.payment as PaymentByDirectDebit)
              .directDebitDetails.sortCode,
          },
        });
      })
    )
  );

  validateDirectDebitDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.validateDirectDebitDetails),
      switchMap(({ checkoutRequest, validateDirectDebitRequest }) => {
        return this.checkoutService
          .validateDirectDebitDetails(validateDirectDebitRequest)
          .pipe(
            map((validateDirectDebitResult) =>
              CheckoutActions.validateDirectDebitDetailsSuccess({
                checkoutRequest,
                validateDirectDebitResult,
              })
            ),
            catchError((error: HttpErrorResponse) => {
              return of(
                CheckoutActions.validateDirectDebitDetailsFailure({ error })
              );
            })
          );
      })
    )
  );

  validateDirectDebitDetailsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.validateDirectDebitDetailsSuccess),
      withLatestFrom(
        this.store$.pipe(select(FeatureConfigSelectors.getCheckoutConfig))
      ),
      switchMap(([action, checkoutConfig]) => {
        const showPaymentDetailsSummaryPage =
          checkoutConfig?.showPaymentDetailsSummaryPage || false;
        if (action.validateDirectDebitResult.is_valid) {
          if (showPaymentDetailsSummaryPage) {
            return of(CheckoutActions.showPaymentDetailsSummaryPage());
          } else {
            return of(
              CheckoutActions.checkoutDirectDebitPayment({
                checkoutRequest: action.checkoutRequest,
              })
            );
          }
        }

        return EMPTY;
      })
    )
  );

  checkout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CheckoutActions.checkoutDirectDebitPayment,
        CheckoutActions.checkoutCardPayment
      ),
      switchMap(({ checkoutRequest }) => {
        return this.checkoutService.checkout(checkoutRequest).pipe(
          map((checkoutResponse) =>
            CheckoutActions.checkoutSuccess({ checkoutResponse })
          ),
          catchError((error: HttpErrorResponse) => {
            return of(CheckoutActions.checkoutFailure({ error }));
          })
        );
      })
    )
  );

  paymentDetailsSummaryPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutActions.showPaymentDetailsSummaryPage),
        tap(() =>
          this.router.navigateByUrl(
            this.config.checkoutPaymentDetailsSummaryPage
          )
        )
      ),
    { dispatch: false }
  );

  paymentDetailsSummarySubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.paymentDetailsSummaryOnSubmit),
      withLatestFrom(
        this.store$.pipe(select(CheckoutSelectors.getGuestUserCheckoutRequest))
      ),
      filter(([, checkoutRequest]) => !!checkoutRequest),
      switchMap(
        ([, checkoutRequest]: [Action, CheckoutRequest | undefined]) => {
          return of(
            CheckoutActions.checkoutDirectDebitPayment({
              checkoutRequest: checkoutRequest as CheckoutRequest,
            })
          );
        }
      )
    )
  );

  checkoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutActions.checkoutSuccess),
        tap(({ checkoutResponse }) => {
          this.router.navigateByUrl(this.config.checkoutOrderConfirmationPage);
          this.store$.dispatch(
            CheckoutActions.sendConfirmSaleTags({ checkoutResponse })
          );
        })
      ),
    { dispatch: false }
  );

  sendConfirmSaleDetailsTags$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutActions.sendConfirmSaleTags),
        tap((action) => {
          const {
            paymentType,
          } = action.checkoutResponse.completedItems[0].item.data.quotes[0].paymentOptions[0];
          this.marketingTagging.tagConfirmSaleDetails(
            action.checkoutResponse,
            paymentType
          );
        })
      ),
    { dispatch: false }
  );

  showLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutActions.startCheckout),
        tap(() => this.loaderService.showLoader())
      ),
    { dispatch: false }
  );

  hideLoaderOnFailures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CheckoutActions.checkoutFailure,
          CheckoutActions.validateDirectDebitDetailsFailure
        ),
        tap(() => this.loaderService.hideLoader())
      ),
    { dispatch: false }
  );

  redirectToErrorPageForCardPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CheckoutActions.checkoutFailure,
          CheckoutActions.validateDirectDebitDetailsFailure
        ),
        this.getCardPaymentInformation(),
        this.handleErrorWhenPayingByCard()
      ),
    { dispatch: false }
  );

  hideLoaderOnInValidPaymentDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutActions.validateDirectDebitDetailsSuccess),
        filter(
          ({ validateDirectDebitResult }) => !validateDirectDebitResult.is_valid
        ),
        tap(() => this.loaderService.hideLoader())
      ),
    { dispatch: false }
  );

  get config() {
    return this.buildConfigService.config;
  }

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private errorService: ErrorService,
    private buildConfigService: BuildConfigService,
    private actions$: Actions,
    private checkoutService: CheckoutApiService,
    private marketingTagging: MarketingTaggingService,
    private store$: Store<
      PersonalDetailsPartialState &
        UserProfilePartialState &
        CardPaymentPartialState &
        SelectPaymentPartialState &
        CheckoutPartialState &
        CheckoutBasketPartialState
    >
  ) {}

  private handleErrorWhenPayingByCard(): MonoTypeOperatorFunction<
    [Action, PaymentType | undefined, string | undefined]
  > {
    return pipe(
      tap(([, , cardPaymentOrderNumber]) =>
        this.errorService.handleError({
          header: 'Something went wrong when creating your plan',
          support: 'Don’t worry, your payment was successful.',
          additionalSupport: cardPaymentOrderNumber,
          contactCustomerService:
            'Call our friendly team to receive your order confirmation on:',
        })
      )
    );
  }

  private getCardPaymentInformation(): OperatorFunction<
    Action,
    [Action, PaymentType | undefined, string | undefined]
  > {
    return pipe(
      withLatestFrom(
        this.store$.pipe(select(SelectPaymentSelectors.getSelectedPaymentType)),
        this.store$.pipe(select(CardPaymentSelectors.getCardPaymentOrderNumber))
      ),
      filter(([, paymentType]) => paymentType === PaymentType.Card)
    );
  }
}
