import * as fs from 'fs';
import * as path from 'path';
import { RuntimeConfig } from '@common/util-models';

export function validateConfigFiles(
  projectRoot: string,
  configKeys: Array<keyof RuntimeConfig>
) {
  const configFiles = [
    'deployment/config.ci.json',
    'deployment/config.dev.json',
    'deployment/config.preprod.json',
    'deployment/config.prod.json',
    'deployment/config.sit.json',
    'deployment/config.test.json',
    'deployment/config.uat.json',
    'src/config.json',
  ].map((file) => [file, path.resolve(path.join(projectRoot, file))]);
  test.todo('Validate that no other config files exist');

  describe.each(configFiles)('%s', (name, configFilePath) => {
    const exists = fs.existsSync(configFilePath);
    it('should exist', () => {
      expect(exists).toBe(true);
    });
    if (!exists) {
      return;
    }

    const configFileContents = JSON.parse(
      fs.readFileSync(configFilePath, {
        encoding: 'utf-8',
      })
    );
    it('should have required properties', () => {
      for (const configKey of configKeys) {
        expect(configFileContents).toHaveProperty(configKey);
        expect(configFileContents[configKey]).toBeTruthy();
      }
    });

    it('should have no extra properties', () => {
      // Check if keys are present
      expect(Object.keys(configFileContents).sort()).toEqual(configKeys.sort());
    });
  });
}
