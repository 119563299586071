<div class="you-are-in-safe-hands">
  <div class="md-row sm-col">
    <!-- Banner -->
    <div class="xs-12 lg-6 ml-0 no-gutter">
      <div class="img-wrapper">
        <img
          class="banner"
          alt="You are in safe hands"
          src="./assets/images/img-son-and-father-with-oven.jpg"
        />
      </div>
    </div>
    <div class="xs-12 lg-6 ml-0 mr-0 no-gutter pb-1 p-2 stats-container">
      <div>
        <h3 class="protect-things">
          Count on us to look after the things you need
        </h3>
        <dl class="md-row">
          <div class="xs-12 lg-6 stats">
            <div class="sm-row xs-col">
              <dt class="xs-12 sm-6 lg-12 stats-label">
                Number of UK customers
              </dt>
              <dd class="xs-12 sm-6 lg-12 stats-amount">7.7 million</dd>
            </div>
          </div>

          <div class="xs-12 lg-6 stats">
            <div class="sm-row xs-col">
              <dt class="xs-12 sm-6 lg-12 stats-label">
                Appliances repaired every year
              </dt>
              <dd class="xs-12 sm-6 lg-12 stats-amount">2.4 million</dd>
            </div>
          </div>

          <div class="xs-12 lg-6 stats">
            <div class="sm-row xs-col">
              <dt class="xs-12 sm-6 lg-12 stats-label">
                Appliances repaired every day
              </dt>
              <dd class="xs-12 sm-6 lg-12 stats-amount">6,400</dd>
            </div>
          </div>

          <div class="xs-12 lg-6 stats">
            <div class="sm-row xs-col">
              <dt class="xs-12 sm-6 lg-12 stats-label">
                Appliances replaced last year
              </dt>
              <dd class="xs-12 sm-6 lg-12 stats-amount">500,000</dd>
            </div>
          </div>
        </dl>
      </div>
      <div class="mr-4 note">
        <span>(All figures accurate, as of August, 2021.)</span>
      </div>
    </div>
  </div>
</div>
