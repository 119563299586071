import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'wl-direct-debit-instruction-confirmation-text',
  templateUrl: './direct-debit-instruction-confirmation-text.component.html',
  styleUrls: ['./direct-debit-instruction-confirmation-text.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectDebitInstructionConfirmationTextComponent {
  @Input() isInsurance: boolean | null = false;
}
