<div class="help text--center">
  <p class="help__intro">Still looking around?</p>
  <h2>Here are some links that you may find helpful:</h2>
  <nav>
    <ul class="help__links">
      <li class="help__link">
        <a
          href="https://www.domesticandgeneral.com"
          target="_blank"
          class="link"
        >
          Home
        </a>
      </li>
      <li class="help__link">
        <a
          href="https://www.domesticandgeneral.com/content/help-advice-section/faq"
          target="_blank"
          class="link"
        >
          Help and advice
        </a>
      </li>
      <li class="help__link">
        <a
          href="https://www.domesticandgeneral.com/shop/en/dg/content/contact-domestic-general"
          target="_blank"
          class="link"
        >
          Contact us
        </a>
      </li>
    </ul>
  </nav>
</div>
