import {
  ApplianceFormValue,
  ApplianceFormValueWithItemId,
} from '@common/util-models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as ApplianceFormDataActions from './appliance-form-data.actions';

export const APPLIANCE_FORM_DATA = 'formData';

export interface ApplianceFormDataState
  extends EntityState<ApplianceFormValueWithItemId> {
  prepopulatedFormValue: ApplianceFormValue | undefined;
}

export const applianceFormDataAdapter: EntityAdapter<ApplianceFormValueWithItemId> = createEntityAdapter<ApplianceFormValueWithItemId>(
  {
    selectId: (applianceFormValue: ApplianceFormValueWithItemId) =>
      applianceFormValue.itemId,
  }
);

export const initialApplianceFormDataState: ApplianceFormDataState = applianceFormDataAdapter.getInitialState(
  {
    prepopulatedFormValue: undefined,
  }
);

export const applianceFormDataReducer = createReducer(
  initialApplianceFormDataState,
  on(
    ApplianceFormDataActions.setPrepopulatedFormValue,
    (state, { formData }): ApplianceFormDataState => ({
      ...state,
      prepopulatedFormValue: formData,
    })
  ),
  on(
    ApplianceFormDataActions.clearPrepopulatedFormValue,
    (state): ApplianceFormDataState => ({
      ...state,
      prepopulatedFormValue: undefined,
    })
  ),
  on(
    ApplianceFormDataActions.saveFormValueWithItemId,
    (state, { formData }): ApplianceFormDataState =>
      applianceFormDataAdapter.addOne(formData, state)
  ),
  on(
    ApplianceFormDataActions.resetFormData,
    (): ApplianceFormDataState => initialApplianceFormDataState
  )
);
