import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-panel-toggle-button',
  templateUrl: './panel-toggle-button.component.html',
  styleUrls: ['./panel-toggle-button.component.scss'],
})
export class PanelToggleButtonComponent {
  @Input() isExpanded = false;

  @Output() toggle: EventEmitter<boolean> = new EventEmitter();

  onToggleClick() {
    this.toggle.emit(!this.isExpanded);
  }
}
