import {
  ApplianceDetailsConfig,
  CheckoutConfig,
  DiscountStoreConfig,
  isRemoteDataError,
  isRemoteDataOK,
  QuotesConfig,
  RemoteData,
  SidedoorConfig,
} from '@common/util-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FeatureConfigState,
  FEATURE_CONFIG_FEATURE_KEY,
} from './feature-config.reducers';

export const getFeatureConfigState = createFeatureSelector<FeatureConfigState>(
  FEATURE_CONFIG_FEATURE_KEY
);

export const getApplianceDetailsConfig = createSelector(
  getFeatureConfigState,
  ({
    applianceDetails,
  }: FeatureConfigState): ApplianceDetailsConfig | undefined => applianceDetails
);

export const getCheckoutConfig = createSelector(
  getFeatureConfigState,
  ({ checkout }: FeatureConfigState): CheckoutConfig | undefined => checkout
);

export const getQuotesConfig = createSelector(
  getFeatureConfigState,
  ({ quotes }: FeatureConfigState): QuotesConfig | undefined => quotes
);

export const getSidedoorConfig = createSelector(
  getFeatureConfigState,
  ({ sidedoor }: FeatureConfigState): SidedoorConfig | undefined => sidedoor
);

export const getDiscountStoreConfig = createSelector(
  getFeatureConfigState,
  ({ discountStore }: FeatureConfigState): DiscountStoreConfig | undefined =>
    discountStore
);

export const getRemoteState = createSelector(
  getFeatureConfigState,
  ({ remoteState }: FeatureConfigState): RemoteData => remoteState
);

export const getRemoteStateLoaded = createSelector(
  getRemoteState,
  (remoteState: RemoteData): boolean =>
    isRemoteDataOK(remoteState) || isRemoteDataError(remoteState)
);
