<p class="customer-service-contact__title">
  {{ title }}
</p>
<p class="customer-service-contact__help-number">
  <a class="link" href="tel:08005978600">0800 5978600</a>
</p>
<strong class="customer-service-contact__available-text">
  We’re available:
</strong>
<p>8am to 8pm Monday – Friday</p>
<p>9am to 5pm Saturday – Sunday</p>
