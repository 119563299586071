import { Injectable } from '@angular/core';
import { User } from '@domgen/dgx-fe-auth/lib/common/models/user';
import jwtDecode from 'jwt-decode';

import { CookieManagerService } from './cookie-manager.service';

@Injectable({
  providedIn: 'any',
})
export class JwtManagerService {
  constructor(private readonly cookieManagerService: CookieManagerService) {}

  decryptJWT(): User | undefined {
    const token: string = this.cookieManagerService.getAuthCookie();
    if (!token) {
      return undefined;
    }
    return jwtDecode<User>(token);
  }
}
