export * from './lib/+state/appliance-details.facade';
export * from './lib/+state/appliance-details.reducer';
export * from './lib/+state/appliance-details.selectors';
export * from './lib/+state/appliance-form-data/appliance-form-data.reducer';
export * from './lib/+state/appliance-form-data/appliance-form-data.selectors';
export * from './lib/+state/appliances/appliances.reducer';
export * from './lib/+state/appliances/appliances.selectors';
export * from './lib/+state/brands/brands.reducer';
export * from './lib/+state/brands/brands.selectors';
export * from './lib/common-data-access-appliance-details.module';
export * from './lib/services/appliance-details-form-data.resolver';
export * from './lib/services/appliances-api.service';
export * from './lib/services/appliances-mapper.service';
export * from './lib/services/appliances.resolver';
export * from './lib/services/appliance-details-query-params-form-data-provider';
export * from './lib/services/appliance-details-side-door-config-form-data-provider';
export * from './lib/services/appliance-details-store-feature-config-form-data-provider';

import * as _ApplianceDetailsActions from './lib/+state/appliance-details.actions';
import * as ApplianceFormDataActions from './lib/+state/appliance-form-data/appliance-form-data.actions';
import * as ApplianceFormDataSelectors from './lib/+state/appliance-form-data/appliance-form-data.selectors';
import * as AppliancesActions from './lib/+state/appliances/appliances.actions';
import * as AppliancesSelectors from './lib/+state/appliances/appliances.selectors';
import * as BrandsActions from './lib/+state/brands/brands.actions';
import * as BrandsSelectors from './lib/+state/brands/brands.selectors';

export const ApplianceDetailsActions = {
  ..._ApplianceDetailsActions,
  ...ApplianceFormDataActions,
  ...AppliancesActions,
  ...BrandsActions,
};

export const ApplianceDetailsSelectors = {
  ...ApplianceFormDataSelectors,
  ...AppliancesSelectors,
  ...BrandsSelectors,
};
