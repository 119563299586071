import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BuildConfigService } from '../../services/build-config/build.config.service';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
  private cmsUrlLog: { url: string; calls: number }[] = [];

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    private config: BuildConfigService
  ) {}

  // skip a URL if it contains a particular sub-string
  shouldUrlBeSkipped(reqUrl: string): boolean {
    // NB: not using buildConfigService to get list of URLs as it creates a catch-22 -
    // fetching config.json requires and HTTP request, which gets itercepted and requires a build config file
    const ignoreUrls = [
      'reporting.domesticandgeneral.com',
      'cms.domesticandgeneral',
      'identity/signin',
      '/magnoliaAuthor/',
      '/identity/password/forgot',
      '/identity/refresh',
    ];

    return !!ignoreUrls.filter((url) => {
      return reqUrl.indexOf(url) >= 0;
    }).length;
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.recaptchaV3Service
      .execute('getToken') // Potentially, this can be request-specific
      .pipe(
        mergeMap((token: string) => {
          if (this.shouldUrlBeSkipped(req.url)) {
            this.logCmsUrls(req.url);
            return next.handle(req);
          } else {
            const newHeaders = req.headers.append('x-recaptcha-token', token);
            const newReq = req.clone({ headers: newHeaders });
            return next.handle(newReq);
          }
        })
      );
  }

  logCmsUrls(url: string) {
    // log cml calls only when in local dev mode
    if (
      this.config.config.cms_config.logCmsRequests &&
      !url.includes('identity/signin')
    ) {
      const matching = this.cmsUrlLog.filter((item) => item.url === url);
      if (matching.length > 0) {
        this.cmsUrlLog[0].calls++;
      } else {
        this.cmsUrlLog.push({ url, calls: 1 });
      }
      console.log(
        'CMS url logger - summary so far:',
        JSON.stringify(
          this.cmsUrlLog.map((item) => item.url),
          null,
          2
        )
      );
    }
  }
}
