import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PersonalDetailsActions from './personal-details.actions';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BuildConfigService } from '@common/util-foundation';
import { PersonalDetailsPartialState } from './personal-details.reducer';
import {
  CheckoutBasketPartialState,
  CheckoutBasketSelectors,
} from '@common/data-access-checkout-basket';
import { select, Store } from '@ngrx/store';
import { MarketingTaggingService } from '@common/data-access-shared';

@Injectable()
export class PersonalDetailsEffects {
  updatePersonalDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PersonalDetailsActions.proceedToPayment),
        withLatestFrom(
          this.store.pipe(
            select(CheckoutBasketSelectors.isDirectDebitOnlyPaymentOption)
          )
        ),
        tap(([, isDirectDebitOnlyPaymentOption]) => {
          isDirectDebitOnlyPaymentOption === false
            ? this.router.navigateByUrl(
                this.buildConfig.config.selectPaymentPage
              )
            : this.router.navigateByUrl(
                this.buildConfig.config.checkoutDirectDebitPage
              );
        })
      ),
    { dispatch: false }
  );

  tagPersonalDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PersonalDetailsActions.tagPersonalDetails),
        tap((action) =>
          this.marketingTaggingService.tagPersonalDetailsPage(
            action.taggingDetails
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private buildConfig: BuildConfigService,
    private store: Store<
      PersonalDetailsPartialState & CheckoutBasketPartialState
    >,
    private router: Router,
    private marketingTaggingService: MarketingTaggingService
  ) {}
}
