import { Overlay } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';
import { DefaultAlertErrorDialogConfig } from '../constants/default-alert-error-dialog-config.constant';
import { AlertDialogConfig } from '../models/alert-dialog-config.interface';
import { AlertDialogRef } from '../models/alert-dialog-ref.type';
import { AlertDialogType } from '../models/alert-dialog-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AlertDialogService {
  private dialogRef?: AlertDialogRef;

  constructor(
    private readonly overlay: Overlay,
    private readonly matDialog: MatDialog
  ) {}

  public openError(config: AlertDialogConfig): AlertDialogRef {
    return this.open({
      ...config,
      data: { ...config.data, type: AlertDialogType.Error },
    });
  }

  public openDefaultError(): AlertDialogRef {
    return this.openError(DefaultAlertErrorDialogConfig);
  }

  public openInfo(config: AlertDialogConfig): AlertDialogRef {
    return this.open({
      ...config,
      data: { ...config.data, type: AlertDialogType.Info },
    });
  }

  public openWarning(config: AlertDialogConfig): AlertDialogRef {
    return this.open({
      ...config,
      data: { ...config.data, type: AlertDialogType.Warning },
    });
  }

  public close(): void {
    this.dialogRef?.close();
    this.dialogRef = undefined;
  }

  public afterClosed(): Observable<void> {
    return this.dialogRef ? this.dialogRef?.afterClosed() : of(undefined);
  }

  private open(config: AlertDialogConfig): AlertDialogRef {
    this.close();
    this.dialogRef = this.matDialog.open(AlertDialogComponent, {
      ...config,
      role: 'alertdialog',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
    return this.dialogRef;
  }
}
