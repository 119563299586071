import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CoverPaymentOptions } from '@common/util-models';
import { filterNullUndefined } from '@common/util-foundation';
import { Observable } from 'rxjs';
import { CheckoutBasketPartialState } from './checkout-basket.reducer';
import * as CheckoutBasketSelectors from './checkout-basket.selectors';
import * as CheckoutBasketActions from './checkout-basket.actions';
import { retryPayingByDirectDebit } from '@common/data-access-shared';

@Injectable()
export class CheckoutBasketFacade {
  basketId$: Observable<string> = this.store$.pipe(
    select(CheckoutBasketSelectors.getBasketId),
    filterNullUndefined()
  );

  paymentOptions$: Observable<CoverPaymentOptions> = this.store$.pipe(
    select(CheckoutBasketSelectors.getPaymentOptions),
    filterNullUndefined()
  );

  constructor(private store$: Store<CheckoutBasketPartialState>) {}

  proceedToCheckout() {
    this.store$.dispatch(CheckoutBasketActions.proceedToCheckout());
  }

  retryDirectDebitPayment() {
    this.store$.dispatch(retryPayingByDirectDebit());
  }
}
