import { Component, HostBinding, Input } from '@angular/core';

export interface DetailSectionData {
  title: string;
  details: DetailSectionDataItem[];
  aside: DetailSectionDataItem;
}

export interface DetailSectionDataItem {
  key: string;
  value: string | null;
  subtitle?: string | null;
}

@Component({
  selector: 'ui-panel-display-info-section',
  templateUrl: './panel-display-info-section.component.html',
  styleUrls: ['./panel-display-info-section.component.scss'],
})
export class PanelDisplayInfoSectionComponent {
  @Input() data: DetailSectionData[] | undefined;
  @Input() footer: DetailSectionDataItem | undefined | null;
  @HostBinding('class.detail-panel__row') someField = true;
}
