<div errorPageGaTagging class="error">
  <div class="icon-container">
    <div class="icon icon-close"></div>
  </div>
  <h1>Oops!</h1>
  <p class="header-desc">Something’s gone wrong.</p>
  <h2 class="help-number">
    Call us at
    <a href="tel:08004970705">0800 497 0705 </a>
    and we’ll help you complete your purchase.
  </h2>
  <div class="timing">
    We’re available 8am to 8pm Monday to Friday, 9am to 5pm on weekends.
  </div>
  <div>
    <div
      *ngIf="
        buildConfig?.config?.backToHomeButton?.text &&
        buildConfig?.config?.backToHomeButton?.href
      "
    >
      <button class="btn btn--secondary" (click)="handleBackToHome()">
        {{ buildConfig?.config?.backToHomeButton?.text }}
      </button>
    </div>
  </div>
</div>
