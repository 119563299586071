import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { RadioListItemValue } from '@domgen/dgx-fe-base-components';
import { Product } from '../product-card/product-card.component';
import { AvailableCover } from '@common/util-models';

import { UpgradeCoverConfig } from '@common/util-models';

@Component({
  selector: 'wl-cover-selection',
  templateUrl: './cover-selection.component.html',
  styleUrls: ['./cover-selection.component.scss'],
})
export class CoverSelectionComponent implements OnChanges {
  @Input() selectedProduct: RadioListItemValue = null;
  @Input() availableCovers: AvailableCover[] | null = [];
  @Input() alternateProduct?: UpgradeCoverConfig | undefined | null;
  @Output() coverChange: EventEmitter<RadioListItemValue> = new EventEmitter();
  @Output()
  alternateProductSelection: EventEmitter<UpgradeCoverConfig> = new EventEmitter();
  @Input() isInsurance: boolean | null = false;

  products: Product[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.availableCovers) {
      return;
    }
    this.mapToProductObject(this.availableCovers ? this.availableCovers : []);
  }

  onSelectionChange(id: RadioListItemValue) {
    if (this.coverChange?.observers?.length) {
      this.coverChange.emit(id);
    } else {
      this.selectedProduct = id;
    }
  }
  handleAlternateProductSelection(product: UpgradeCoverConfig) {
    this.alternateProductSelection.emit(product);
  }

  mapToProductObject(availableCovers: AvailableCover[]) {
    const initialCover = availableCovers.find((cover) => !cover.isUpgrade);
    const initialCoverPrice =
      initialCover?.paymentOptions.directDebit?.subsequentPayment || 0;
    this.products = availableCovers.map((cover: AvailableCover) => {
      const coverPrice =
        cover.paymentOptions.directDebit?.subsequentPayment || 0;
      const coverObj: Product = {
        id: cover.coverType,
        title: (cover.isUpgrade ? 'Upgrade to ' : '') + cover.name,
        perMonthPrice: coverPrice,
        extraCost: cover.isUpgrade ? coverPrice - initialCoverPrice : 0,
        yearlyPrice: cover.paymentOptions.directDebit?.fee || 0,
        isUpgrade: cover.isUpgrade,
        desc: cover.description,
      };
      return coverObj;
    });
  }
}
