export enum AddressFieldNames {
  Line1 = 'line1',
  Line2 = 'line2',
  County = 'county',
  City = 'city',
  Postcode = 'postcode',
  Type = 'type',
  LookupMode = 'mode',
}

export enum SelectionMode {
  Lookup = 'lookup',
  Manual = 'manual',
  List = 'list',
}
