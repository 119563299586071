import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedUiBaseComponentsModule } from '@domgen/dgx-fe-base-components';
import { CoversExclusionsComponent } from './covers-exclusions.component';

@NgModule({
  imports: [CommonModule, SharedUiBaseComponentsModule],
  declarations: [CoversExclusionsComponent],
  exports: [CoversExclusionsComponent],
})
export class CoverExclusionsModule {}
