import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ErrorService } from '@common/util-foundation';

@Component({
  selector: 'sales-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {
  public readonly errorPageData$ = this.errorService.errorPageData$;

  constructor(private errorService: ErrorService) {}
}
