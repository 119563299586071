import { Input, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ui-card-radio',
  templateUrl: './card-radio.component.html',
  styleUrls: ['./card-radio.component.scss'],
})
export class CardRadioComponent {
  @Input() selected = false;

  @Output() valueChange: EventEmitter<boolean> = new EventEmitter();

  onInputChange(event: Event) {
    if (event && event.target && 'checked' in event.target) {
      const target = event.target as HTMLInputElement;
      this.valueChange.emit(target.checked);
    }
  }
}
