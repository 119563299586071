import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SelectQuotePaymentOptionsFacade } from '@common/data-access-quotes';
import { ResetStateFacade } from '@common/data-access-shared';
import { UserProfileFacade } from '@common/data-access-user-profile';
import { fadeInOut } from '@common/ui-sales-components';
import {
  BuildConfigService,
  LoaderService,
  SessionInactivityService,
} from '@common/util-foundation';
import { BuildConfig } from '@common/util-models';
import { AuthLibFacade } from '@domgen/dgx-fe-auth';
import { AlertDialogService } from '@domgen/dgx-fe-base-components';
import { NavigationLink } from '@domgen/dgx-fe-business-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthStateService } from '@shared/feature-auth';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'sales-app-root',
  templateUrl: './sales-app.component.html',
  styleUrls: ['./sales-app.component.scss'],
  animations: [fadeInOut],
})
export class SalesAppComponent implements OnInit {
  title = 'sales';
  config: BuildConfig = this.buildConfigService.config;
  headerNavigationLinks: NavigationLink[] = [
    this.config.header?.products,
    this.config.header?.repair,
    this.config.header?.faq,
  ];
  loading$!: Observable<boolean>;
  isCheckoutPage = false;

  public progressSteps$:
    | Observable<{ label: string; routes: string[] }[]>
    | undefined;
  public currentRoute = '';

  constructor(
    public readonly buildConfigService: BuildConfigService,
    public readonly userProfileFacade: UserProfileFacade,
    private readonly authFacade: AuthLibFacade,
    private readonly authStateService: AuthStateService,
    private readonly alertDialogService: AlertDialogService,
    private readonly loaderService: LoaderService,
    private readonly resetStateFacade: ResetStateFacade,
    private readonly router: Router,
    private readonly selectQuotePaymentOptionsFacade: SelectQuotePaymentOptionsFacade,
    private readonly sessionInactivityService: SessionInactivityService
  ) {
    router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (e.url) {
          this.currentRoute = e.url;
          this.isCheckoutPage =
            this.buildConfigService.config.checkoutLoggedInUserPage ===
            this.currentRoute;
        }
      });

    this.progressSteps$ = combineLatest([
      this.selectQuotePaymentOptionsFacade.paymentOptions$,
      this.authFacade.isAuthenticated$,
    ]).pipe(
      map(([paymentOptions, isAuthenticated]) => {
        // In case there are more then one payment method then it should be boiler (heating equipment)
        return this.getSteps(
          isAuthenticated,
          paymentOptions.card !== undefined &&
            paymentOptions.directDebit !== undefined
        );
      })
    );
  }

  ngOnInit(): void {
    this.loading$ = this.loaderService.loading$;
    this.authStateService.setUpdateAuthState();
    this.monitorSessionInactivity();
  }

  private getSteps(
    isAuthenticated: boolean,
    isHeating: boolean
  ): { label: string; routes: string[] }[] {
    const yourDetails = {
      label: 'Your Details',
      routes: ['/checkout/personal-details'],
    };
    const paymentMethod = {
      label: 'Payment Method',
      routes: ['/checkout/select-payment'],
    };
    const paymentDetails = {
      label: 'Payment Details',
      routes: [
        '/checkout/direct-debit-payment',
        '/checkout/card-payment',
        '/checkout/credit-card-payment',
        '/checkout/payment-details-summary',
        '/checkout/logged-in',
      ],
    };
    const confirmation = {
      label: 'Confirmation',
      routes: ['/checkout/order-confirmation'],
    };
    return [
      ...(!isAuthenticated ? [yourDetails] : []),
      ...(isHeating ? [paymentMethod] : []),
      paymentDetails,
      confirmation,
    ];
  }

  private monitorSessionInactivity(): void {
    this.sessionInactivityService
      .monitor()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.alertDialogService.openError({
          ariaLabel: 'session expired',
          disableClose: true,
          data: {
            title: 'Your session has timed out due to inactivity',
            body: 'Click below to restart the journey',
            primaryCta: {
              text: 'Restart journey',
              click: () => this.resetStateFacade.sessionInactivityTimeout(),
            },
          },
        });
      });
  }
}
