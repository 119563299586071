import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-panel-title',
  templateUrl: './panel-title.component.html',
  styleUrls: ['./panel-title.component.scss'],
})
export class PanelTitleComponent {
  @Input() variant: 'normal' | 'highlight' = 'normal';
}
