import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  ApplianceDetailsQueryParamsFormDataProvider,
  ApplianceDetailsSidedoorConfigFormDataProvider,
  ApplianceDetailsStoreFeatureConfigFormDataProvider,
} from '@common/data-access-appliance-details';
import {
  CorrelationIdPartialState,
  correlationIdReducer,
  CORRELATION_ID_KEY,
  RestartJourneyEffects,
} from '@common/data-access-shared';
import {
  AppProvidersConfig,
  AppProvidersConfigToken,
  CommonFeatureCoreModule,
  META_REDUCERS,
} from '@common/feature-core';
import {
  ApplianceDetailsUiModule,
  CommonUiSalesComponentsModule,
} from '@common/ui-sales-components';
import {
  APPLIANCE_DETAILS_FORM_DATA_PROVIDER,
  BuildConfigService,
  ENVIRONMENT,
} from '@common/util-foundation';
import { SharedUiBaseComponentsModule } from '@domgen/dgx-fe-base-components';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@sales/util-environment';
import { SharedDataAccessNavigationModule } from '@shared/data-access-navigation';
import {
  refreshApiConfigToken,
  SharedFeatureAuthModule,
} from '@shared/feature-auth';
import { SalesAppComponent } from './components/sales-app.component';
import { NAVIGATION_RULES } from './modules/navigation-rules.config';
import { SalesFeatureShellRoutingModule } from './sales-feature-shell-router.module';

const modules = [
  SalesFeatureShellRoutingModule,
  CommonUiSalesComponentsModule,
  HttpClientModule,
  SharedUiBaseComponentsModule,
];

@NgModule({
  declarations: [SalesAppComponent],
  imports: [
    ...modules,
    CommonModule,
    StoreModule.forRoot(
      {
        [CORRELATION_ID_KEY]: correlationIdReducer,
      } as ActionReducerMap<
        CorrelationIdPartialState & Record<string, unknown>
      >,
      {
        metaReducers: !environment.production
          ? [...META_REDUCERS]
          : [...META_REDUCERS],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    SharedDataAccessNavigationModule.forRoot({
      defaultRedirectUrl: environment.applianceDetailsPage,
      navigationRules: [...NAVIGATION_RULES],
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CommonFeatureCoreModule,
    EffectsModule.forRoot([RestartJourneyEffects]),
    SharedFeatureAuthModule,
    ApplianceDetailsUiModule,
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: refreshApiConfigToken,
      useFactory: (buildConfigService: BuildConfigService) =>
        buildConfigService.config.refreshAPI,
      deps: [BuildConfigService],
    },
    {
      provide: APPLIANCE_DETAILS_FORM_DATA_PROVIDER,
      multi: true,
      useClass: ApplianceDetailsQueryParamsFormDataProvider,
    },
    {
      provide: APPLIANCE_DETAILS_FORM_DATA_PROVIDER,
      multi: true,
      useClass: ApplianceDetailsStoreFeatureConfigFormDataProvider,
    },
    {
      provide: APPLIANCE_DETAILS_FORM_DATA_PROVIDER,
      multi: true,
      useClass: ApplianceDetailsSidedoorConfigFormDataProvider,
    },
  ],
  exports: [...modules, SalesAppComponent],
})
export class SalesFeatureShellModule {
  static forRoot(
    navigationConfig: AppProvidersConfig
  ): ModuleWithProviders<SalesFeatureShellModule> {
    return {
      ngModule: SalesFeatureShellModule,
      providers: [
        { provide: AppProvidersConfigToken, useValue: navigationConfig },
      ],
    };
  }
}
