<div
  class="what-you-get align--stretch justify--center mt-2 p-2"
  [ngSwitch]="productType"
>
  <ng-template [ngSwitchCase]="maintenance">
    <div class="flex text--center align--stretch justify--center mb-2">
      <h2>What you'll get with a maintenance and support plan</h2>
    </div>
    <div class="what-you-get-items lg-row md-row sm--stack xs--stack">
      <div class="flex md-4">
        <div class="what-you-get-items-icon">
          <img
            src="./assets/images/catalogue-icons/icon-contact-us.svg"
            alt="contact us icon"
          />
        </div>
        <div class="what-you-get-items-content">
          <p class="lg--stack md--stack xs--stack">
            <strong>Ongoing maintenance and support</strong>
          </p>
          <p class="lg--stack md--stack xs--stack">
            to help keep your boiler running smoothly
          </p>
        </div>
      </div>
      <div class="flex md-4">
        <div class="what-you-get-items-icon">
          <img
            src="./assets/images/catalogue-icons/icon-boiler.svg"
            alt="boiler icon"
          />
        </div>
        <div class="what-you-get-items-content">
          <p class="lg--stack md--stack xs--stack">
            <strong>Exclusive access to our boiler care portal</strong>
          </p>
          <p class="lg--stack md--stack xs--stack">
            For tips to keep your boiler,controls and system in good working
            order
          </p>
        </div>
      </div>
      <div class="flex md-4">
        <div class="what-you-get-items-icon">
          <img
            src="./assets/images/catalogue-icons/icon-repair.svg"
            alt="repair icon"
          />
        </div>
        <div class="what-you-get-items-content">
          <p class="lg--stack md--stack xs--stack">
            <strong>An annual service</strong>
          </p>
          <p class="lg--stack md--stack xs--stack">
            by our nationwide network of expert Gas safe registered engineers
          </p>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="insurance">
    <div class="flex text--center align--stretch justify--center mb-2">
      <h2>What you'll get with a protection policy</h2>
    </div>
    <div class="what-you-get-items lg-row md-row sm--stack xs--stack">
      <div class="flex md-4">
        <div class="what-you-get-items-icon">
          <img
            src="./assets/images/catalogue-icons/icon-protection.svg"
            alt="protection icon"
          />
        </div>
        <div class="what-you-get-items-content">
          <p class="lg--stack md--stack xs--stack">
            <strong>Protection against breakdowns</strong>
          </p>
          <p class="lg--stack md--stack xs--stack">
            to help get your appliance back up and running
          </p>
        </div>
      </div>
      <div class="flex md-4">
        <div class="what-you-get-items-icon">
          <img
            src="./assets/images/catalogue-icons/icon-washing-machine.svg"
            alt="washing machine icon"
          />
        </div>
        <div class="what-you-get-items-content">
          <p class="lg--stack md--stack xs--stack">
            <strong>Accidental damage cover</strong>
          </p>
          <p class="lg--stack md--stack xs--stack">
            To help your appliance against life's unexpected mishaps
          </p>
        </div>
      </div>
      <div class="flex md-4">
        <div class="what-you-get-items-icon">
          <img
            src="./assets/images/catalogue-icons/icon-repair.svg"
            alt="repair icon"
          />
        </div>
        <div class="what-you-get-items-content">
          <p class="lg--stack md--stack xs--stack">
            <strong>Unlimited repairs</strong>
          </p>
          <p class="lg--stack md--stack xs--stack">
            By our nationwide network of expert engineers
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</div>
