<label class="radio-list-item" [ngClass]="{ selected: selected }">
  <input
    class="pure radio-selection"
    type="radio"
    (change)="onChange(value)"
    [checked]="selected"
  />
  <span class="radio-title">{{ label }}</span>
  <div class="radio-desc" *ngIf="desc">
    <div>
      {{ desc }}
    </div>
    <div *ngIf="subDesc">
      {{ subDesc }}
    </div>
  </div>
</label>
