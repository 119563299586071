import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { RadioListItemValue } from '../radio-list-item/radio-list-item.component';

export interface RadioItemData {
  value: RadioListItemValue;
  label: string;
  desc: string;
  subDesc?: string;
}

@Component({
  selector: 'ui-radio-list',
  templateUrl: './radio-list.component.html',
  styleUrls: ['./radio-list.component.scss'],
})
export class RadioListComponent {
  @Input() value: RadioListItemValue = null;

  @Input() list: RadioItemData[] | undefined;
  @Output()
  selectionChange: EventEmitter<RadioListItemValue> = new EventEmitter();

  onChange(value: RadioListItemValue) {
    this.selectionChange.emit(value);
  }
}
