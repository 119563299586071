<ui-panel>
  <ui-panel-header>
    <ui-panel-title>Your details</ui-panel-title>
    <ui-panel-action-button
      *ngIf="editLink"
      [buttonText]="'Edit'"
      (buttonClick)="onButtonClick()"
    ></ui-panel-action-button>
  </ui-panel-header>
  <ui-panel-body *ngIf="detail">
    <div class="detail-panel-item" *ngIf="detail?.name">{{ detail.name }}</div>
    <div class="detail-panel-item" *ngIf="detail?.email">
      {{ detail.email }}
    </div>

    <div class="detail-panel-item" *ngIf="detail?.landline">
      {{ detail.landline }}
    </div>
    <div class="detail-panel-item" *ngIf="detail?.mobile && !detail?.landline">
      {{ detail.mobile }}
    </div>
    <div class="detail-panel-item" *ngIf="address">
      {{ address }}
    </div>
  </ui-panel-body>
</ui-panel>
