import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent {
  @Input() align: 'left' | 'right' | 'center' = 'left';
}
