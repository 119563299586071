import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';

import { refreshApiConfigToken } from '../config/refresh-api.token';

@Injectable({
  providedIn: 'any',
})
export class RefreshTokenService {
  constructor(
    private readonly httpClient: HttpClient,
    @Optional()
    @Inject(refreshApiConfigToken)
    private readonly refreshApi: string
  ) {}

  getRefreshToken(): Observable<string> {
    if (!this.refreshApi) {
      throw new Error(
        'getRefreshToken requires a provider for refreshApiConfigToken <string> for refreshApi url'
      );
    }

    return this.httpClient.post<string>(
      this.refreshApi,
      {},
      {
        withCredentials: true,
      }
    );
  }
}
