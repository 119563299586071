import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'sales-customer-service-contact',
  templateUrl: './customer-service-contact.component.html',
  styleUrls: ['./customer-service-contact.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerServiceContactComponent {
  @Input() title!: string;
}
