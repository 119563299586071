<div class="ui-list-item">
  <ng-container>
    <i
      *ngIf="icon"
      class="icon icon-{{ icon }} ui-list-item__icon"
      [ngClass]="{
        'ui-list-item__icon--fill': iconFill
      }"
      [style.background]="iconFill && getBackgroundFill()"
    ></i>
    <div class="ui-list-item__text">
      <ng-content></ng-content>
    </div>
  </ng-container>
</div>
