import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as CheckoutActions from './checkout.actions';

import {
  CheckoutCompletedItem,
  RemoteData,
  remoteDataError,
  remoteDataLoading,
  remoteDataNotFetched,
  remoteDataOK,
} from '@common/util-models';

export const CHECKOUT_FEATURE_KEY = 'checkout';

export interface CheckoutState extends EntityState<CheckoutCompletedItem> {
  remoteStateCheckout: RemoteData<null>;
  remoteStateValidateDirectDebitDetails: RemoteData<null>;
  directDebitDetailsValid?: boolean;
}

export interface CheckoutPartialState {
  readonly [CHECKOUT_FEATURE_KEY]: CheckoutState;
}

export const checkoutAdapter: EntityAdapter<CheckoutCompletedItem> = createEntityAdapter<CheckoutCompletedItem>(
  {
    selectId: (checkedOutItem) => checkedOutItem.item.itemId,
  }
);

export const initialState: CheckoutState = checkoutAdapter.getInitialState({
  remoteStateCheckout: remoteDataNotFetched(),
  remoteStateValidateDirectDebitDetails: remoteDataNotFetched(),
  directDebitDetailsValid: undefined,
  config: {
    showPaymentDetailsSummaryPage: false,
  },
});

const checkoutReducer = createReducer(
  initialState,

  on(CheckoutActions.startCheckout, (state) => {
    return checkoutAdapter.removeAll({
      ...state,
      remoteStateValidateDirectDebitDetails: remoteDataNotFetched(),
      remoteStateCheckout: remoteDataNotFetched(),
    });
  }),

  on(CheckoutActions.validateDirectDebitDetails, (state) => ({
    ...state,
    remoteStateValidateDirectDebitDetails: remoteDataLoading(),
    directDebitDetailsValid: undefined,
  })),

  on(
    CheckoutActions.validateDirectDebitDetailsSuccess,
    (state, { validateDirectDebitResult }) => ({
      ...state,
      remoteStateValidateDirectDebitDetails: remoteDataOK(null),
      directDebitDetailsValid: validateDirectDebitResult.is_valid,
    })
  ),

  on(CheckoutActions.validateDirectDebitDetailsFailure, (state, { error }) => ({
    ...state,
    remoteStateValidateDirectDebitDetails: remoteDataError(error),
    directDebitDetailsValid: undefined,
  })),

  on(
    CheckoutActions.checkoutCardPayment,
    CheckoutActions.checkoutDirectDebitPayment,
    (state) => ({
      ...state,
      remoteStateCheckout: remoteDataLoading(),
    })
  ),

  on(
    CheckoutActions.exitingCheckoutGuestUser,
    CheckoutActions.exitingCheckoutLoggedInUser,
    (state) => ({
      ...state,
      directDebitDetailsValid: undefined,
    })
  ),

  on(CheckoutActions.checkoutFailure, (state, { error }) => {
    return {
      ...state,
      remoteStateCheckout: remoteDataError(error),
    };
  }),

  on(CheckoutActions.checkoutSuccess, (state, { checkoutResponse }) => {
    return checkoutAdapter.setAll(checkoutResponse.completedItems, {
      ...state,
      remoteStateCheckout: remoteDataOK(null),
    });
  })
);

export function reducer(state: CheckoutState | undefined, action: Action) {
  return checkoutReducer(state, action);
}
