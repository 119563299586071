import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  FormGroupTyped,
  FormStatus,
} from '../_shared/interfaces/angular-forms-typed.interface';
import {
  ControlType,
  FieldDef,
  InvalidFormSubmissionPayload,
  MonthYearUpdater,
} from '../_shared/interfaces/dynamic-formbuilder.interface';
import { DynamicFormbuilderService } from '../_shared/services/dynamic-formbuilder.service';
import { ScrollToFirstInvalidControlService } from '../services/scroll-to-first-invalid-control.service';

/** @dynamic */
@UntilDestroy()
@Component({
  selector: 'dgx-dfb-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnInit {
  @ContentChild('projected', { read: TemplateRef })
  projected!: TemplateRef<unknown>;

  @Input() validate = false;
  @Input() form!: FormGroup | FormGroupTyped<unknown>;
  @Input() fieldset!: FieldDef[];
  @Input() controlsToHide: string[] = [];
  @Input() monthYearDateUpdater: MonthYearUpdater | undefined;
  @Input() scrollToFirstErrorOnSubmit = true;

  @Output() valueChanges: EventEmitter<unknown> = new EventEmitter();
  @Output() statusChanges: EventEmitter<FormStatus> = new EventEmitter();
  @Output() validatedSubmit: EventEmitter<unknown> = new EventEmitter();
  @Output()
  invalidSubmit: EventEmitter<InvalidFormSubmissionPayload> = new EventEmitter();
  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  readonly controlType = ControlType; // expose enum to template

  constructor(
    private dfb: DynamicFormbuilderService,
    private sfic: ScrollToFirstInvalidControlService
  ) {}

  ngOnInit(): void {
    this.validateFieldset();
    this.form = this.form || this.dfb.generateFormGroup(this.fieldset || []);
    this.subscribeFormEmitters();
    this.setValidForm();
  }

  setValidForm() {
    if (this.validate) {
      this.onSubmit();
    }
    if (this.form.valid) {
      this.validate = false;
      this.form?.markAllAsTouched();
    }
  }

  onSubmit() {
    if (this.form?.valid) {
      this.validatedSubmit.emit(this.form.value);
    } else {
      this.form?.markAllAsTouched();
      this.validate = true;
      this.invalidSubmit.emit(this.getInvalidFormSubmissionPayload());
      if (this.scrollToFirstErrorOnSubmit) {
        this.sfic.scrollToFirstInvalidControl();
      }
    }
  }

  getFieldToShow(field: FieldDef): boolean {
    if (
      field.controlType === ControlType.SUBMIT &&
      this.controlsToHide.length !== 0 &&
      this.controlsToHide.some((x) => x === ControlType.SUBMIT)
    ) {
      return false;
    }
    const controlName = (field as { controlName: string }).controlName || '';

    return (
      this.controlsToHide.length === 0 ||
      !this.controlsToHide.includes(controlName)
    );
  }

  private subscribeFormEmitters() {
    this.form?.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => {
      this.valueChanges.emit(val);
    });
    this.form?.statusChanges.pipe(untilDestroyed(this)).subscribe((val) => {
      this.statusChanges.emit(<FormStatus>val);
    });
  }

  private validateFieldset() {
    if (!this.fieldset) {
      throw new Error(`'fieldset' is a required input to FormComponent`);
    }
  }

  private getInvalidFormSubmissionPayload(): InvalidFormSubmissionPayload {
    return {
      value: this.form.value,
      fieldset: this.fieldset,
      form: this.form,
    };
  }

  handleAnalytics(event: AnalyticsData | undefined) {
    if (event) {
      this.analytics.emit(event);
    }
  }
}
