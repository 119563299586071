import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const toggleExpandedAnimation = trigger('toggleExpanded', [
  state(
    'expanded',
    style({
      height: '*',
    })
  ),
  state(
    'collapsed',
    style({
      height: '0px',
    })
  ),
  transition('expanded => collapsed', [animate('200ms 0s ease')]),
  transition('collapsed => expanded', [animate('200ms 0s ease')]),
]);
