import { Injectable } from '@angular/core';
import { RedeyeTaggingService } from '@common/util-foundation';
import {
  BasketItem,
  BasketItemPaymentOption,
  BasketItemQuoteDetails,
  CheckoutResponse,
  PaymentType,
  TaggingPersonalDetails,
} from '@common/util-models';

@Injectable({
  providedIn: 'root',
})
export class MarketingTaggingService {
  constructor(private redeyeTaggingService: RedeyeTaggingService) {}

  get saleConfirmTags() {
    return {
      nourl: 'sale-confirm',
      sale_conf: 'saleconf',
      sale_type: 'mailer',
      items: '1',
    };
  }

  formatQuoteTagParams(basketItem: BasketItem) {
    const { quotes } = basketItem.data;
    const { applianceDetails } = basketItem.data;
    const formattedTagParamsList = quotes.map((quote) => {
      const tagParams = {
        nourl: 'quote_step1',
        quote: 'step1',
      };

      const paymentOptions = this.filterDebitPaymentWhenMoreThanOnePaymentOption(
        quote
      );
      const quote_result_monthly = (quote.paymentTerm === 'FT'
        ? paymentOptions.paymentType === PaymentType.Card
          ? 0
          : paymentOptions.subsequentPayment
        : paymentOptions.fee
      )
        .toFixed(2)
        .toString();
      const quote_result_annual = (quote.paymentTerm === 'FT'
        ? paymentOptions.fee
        : paymentOptions.fee * 12
      )
        .toFixed(2)
        .toString();

      return Object.assign(
        tagParams,
        applianceDetails.applianceName && {
          quote1_appliance: applianceDetails.applianceName,
        },
        applianceDetails.purchasePrice && {
          quote1_purch_price: applianceDetails.purchasePrice.toString(),
        },
        applianceDetails.brandName && {
          quote1_brand: applianceDetails.brandName.toString(),
        },
        applianceDetails.purchaseMonth && {
          quote1_purch_month: applianceDetails.purchaseMonth.toString(),
        },
        applianceDetails.purchaseYear && {
          quote1_purch_year: applianceDetails.purchaseYear.toString(),
        },
        applianceDetails.inWarranty && {
          quote1_in_guarantee: applianceDetails.inWarranty ? 'Y' : 'N',
        },
        quote && { quote_result_conf: quote ? 'Y' : 'N' },
        quote_result_monthly && { quote_result_monthly },
        quote_result_annual && { quote_result_annual },
        quote.paymentTerm && { quote_result_term: quote.paymentTerm },
        basketItem.requestAction && {
          request_action: basketItem.requestAction,
        },
        basketItem.requestSource && { request_source: basketItem.requestSource }
      );
    });
    return formattedTagParamsList;
  }

  filterDebitPaymentWhenMoreThanOnePaymentOption(
    quote: BasketItemQuoteDetails
  ): BasketItemPaymentOption {
    return quote.paymentOptions.length > 1
      ? quote.paymentOptions.filter(
          (option) => option.paymentType === PaymentType.DirectDebit
        )[0]
      : quote.paymentOptions[0];
  }

  tagQuotePage(basket: BasketItem[]): void {
    const queryParamsList = this.formatQuoteTagParams(basket[0]);
    this.redeyeTaggingService.tag(queryParamsList);
  }

  tagConfirmSaleDetails(
    checkoutResponse: CheckoutResponse,
    paymentType: PaymentType
  ): void {
    const payment =
      checkoutResponse.completedItems[0].item.data.quotes[0].paymentOptions[0];
    const queryParams = Object.assign(
      this.saleConfirmTags,
      (paymentType === PaymentType.DirectDebit && {
        total_monval: payment.fee?.toFixed(2).toString(),
      }) ||
        undefined,
      {
        total_val: (payment.fee * payment.subsequentPayment)
          ?.toFixed(2)
          ?.toString(),
      },
      { order_ref: checkoutResponse.completedItems[0].confirmation.planNumber }
    );
    this.redeyeTaggingService.tag([queryParams]);
  }

  tagPersonalDetailsPage(personalDetails: TaggingPersonalDetails): void {
    const tagParams = {
      nourl: 'personaldet',
      application: 'personaldet',
      title: personalDetails.title,
      firstname: personalDetails.firstName,
      lastname: personalDetails.lastName,
      email: personalDetails.email,
      mobile: personalDetails.mobileNumber,
      postcode: personalDetails.postcode,
      emailpermit: personalDetails.marketingPreferencesForm?.email ? 'Y' : 'N',
      phonepermit: personalDetails.marketingPreferencesForm?.phone ? 'Y' : 'N',
      postalpermit: personalDetails.marketingPreferencesForm?.post ? 'Y' : 'N',
      optout_52_DG: personalDetails.marketingPreferencesForm?.email ? '1' : '0',
    };
    this.redeyeTaggingService.tag([tagParams]);
  }
}
