import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonUiWhitelabelComponentsModule } from '@common/ui-whitelabel-components';
import { ForgotPasswordModule, LogInModule } from '@domgen/dgx-fe-auth';

import { BoilerTotalMonthPlanComponent } from './boiler-total-month-plan/boiler-total-month-plan.component';
import { CheckoutAsGuestComponent } from './checkout-as-guest/checkout-as-guest.component';
import { CheckoutUserLoginComponent } from './checkout-user-login/checkout-user-login.component';
import { CommonUiSalesComponentsModule } from './common-ui-sales-components.module';
import { DocumentsComponent } from './documents/documents.component';
import { FaqComponent } from './faq/faq.component';
import { FaqsComponent } from './faqs/faqs.component';
import { MinimumTermComponent } from './minimum-term/minimum-term.component';
import { NeedToKnowComponent } from './need-to-know/need-to-know.component';
import { TrustBoxUiModule } from './trust-box-ui.module';
import { WhatYouWillGetComponent } from './what-you-will-get/what-you-will-get.component';
import { TabsComponentsModule } from '@domgen/dgx-fe-base-components';

@NgModule({
  imports: [
    CommonModule,
    CommonUiSalesComponentsModule,
    CommonUiWhitelabelComponentsModule,
    LogInModule,
    ForgotPasswordModule,
    TrustBoxUiModule,
    TabsComponentsModule,
  ],
  declarations: [
    DocumentsComponent,
    MinimumTermComponent,
    NeedToKnowComponent,
    CheckoutAsGuestComponent,
    CheckoutUserLoginComponent,
    BoilerTotalMonthPlanComponent,
    FaqsComponent,
    FaqComponent,
    WhatYouWillGetComponent,
  ],
  exports: [
    DocumentsComponent,
    CheckoutAsGuestComponent,
    CheckoutUserLoginComponent,
    MinimumTermComponent,
    NeedToKnowComponent,
    BoilerTotalMonthPlanComponent,
    FaqsComponent,
    WhatYouWillGetComponent,
  ],
})
export class QuoteUiModule {}
