import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-panel-action-button',
  templateUrl: './panel-action-button.component.html',
  styleUrls: ['./panel-action-button.component.scss'],
})
export class PanelActionButtonComponent {
  @Input() buttonText = '';
  @Output() buttonClick = new EventEmitter();

  onActionButtonClick() {
    this.buttonClick.emit();
  }
}
