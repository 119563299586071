import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ValidationIconComponent } from './validation-icon/validation-icon.component';

const components = [ValidationIconComponent];
@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class FormComponentsModule {}
