import { Injectable } from '@angular/core';
import { FeatureConfigFacade } from '@common/data-access-feature-config';
import {
  ApplianceDetailsFormDataProvider,
  ApplianceFormSubmit,
  ParseResponse,
  ParseResult,
} from '@common/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ApplianceDetailsSidedoorConfigFormDataProvider
  implements ApplianceDetailsFormDataProvider {
  priority = 2;
  constructor(private featureConfigFacade: FeatureConfigFacade) {}
  getApplianceDetailsForm(): Observable<ParseResponse<ApplianceFormSubmit>> {
    return this.featureConfigFacade.sidedoor$(false).pipe(
      map((sideDoorConfig) => {
        return sideDoorConfig?.applianceDetailsFormData
          ? {
              result: ParseResult.Success,
              model: sideDoorConfig.applianceDetailsFormData,
            }
          : { result: ParseResult.NotApplied };
      })
    );
  }
}
