<dgx-dfb-field-layout
  [id]="id"
  [label]="field?.label?.text"
  [tooltip]="field?.tooltip"
  [hint]="field?.hint"
  [errorMessage]="errorMessage"
  data-hook="dgx-sales-list-box"
>
  <div class="listbox__wrapper" *ngIf="field">
    <ui-form-validation-icon
      [validityStatus]="validity"
    ></ui-form-validation-icon>
    <div class="listbox__input-wrapper" *ngIf="hasValue">
      <input
        class="form-control"
        autocomplete="off"
        type="hidden"
        [name]="field?.controlName"
        [placeholder]="field?.placeholder"
        [value]="value"
        [disabled]="true"
      />
      <div class="listbox__highlighted-text">
        <dgx-dfb-hightlighted-text
          [text]="value"
          [highlightText]="field?.hightlightedText"
        ></dgx-dfb-hightlighted-text>
      </div>

      <i
        class="icon icon--lg listbox__trigger"
        [ngClass]="{
          'icon-close': value !== ''
        }"
        (click)="clearControl()"
      ></i>
    </div>
    <div class="listbox__options" *ngIf="!hasValue">
      <dgx-dfb-list-overlay
        [hasError]="ngControl?.control?.invalid && ngControl?.control?.touched"
        [options]="field.optionsStream$ | async"
        [hasScroll]="true"
        [activeOptionIndex]="activeOptionIndex"
        [hightlightedText]="field?.hightlightedText"
        (emitEvent)="onEmitEvent($event)"
      ></dgx-dfb-list-overlay>
    </div>
  </div>
</dgx-dfb-field-layout>
