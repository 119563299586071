<div class="alert-dialog alert-dialog--{{ data.type }}">
  <div class="alert-dialog__content flex">
    <div class="alert-dialog__main">
      <div class="alert-dialog__icon">
        <span class="icon icon-alert"></span>
      </div>
      <div class="alert-dialog__main-body">
        <h4 class="alert-dialog__title mb-1">
          {{ data.title }}
        </h4>
        <p
          *ngFor="let paragraph of body"
          [innerHtml]="paragraph"
          class="alert-dialog__body mb-1"
        ></p>
      </div>
    </div>
    <div class="mt-2 alert-dialog__actions">
      <ui-button
        (click)="onPrimaryClick()"
        [icon]="!data?.primaryCta?.hiddenIcon ? 'arrow-right' : ''"
        class="alert-dialog__primary-cta"
      >
        {{ data.primaryCta.text }}
      </ui-button>
      <ui-button
        *ngIf="data.secondaryCta"
        (click)="onSecondaryClick()"
        [icon]="!data?.secondaryCta?.hiddenIcon ? 'arrow-right' : ''"
        [variant]="'secondary'"
        class="alert-dialog__secondary-cta mt-1"
      >
        {{ data.secondaryCta.text }}
      </ui-button>
    </div>
  </div>
</div>
