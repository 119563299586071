import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() variant:
    | 'primary'
    | 'primary-inverted'
    | 'secondary'
    | 'tertiary'
    | 'toggle' = 'primary';
  @Input() icon: string | undefined;
  @Input() iconSet = 'icon';
  @Input() iconAlign: 'left' | 'right' = 'right';
  @Input() type: HTMLButtonElement['type'] = 'button';
  @Input() disabled = false;
  @Input() disableMode: 'disable' | 'visual' = 'visual';
  @Input() tabIndex: number | undefined = 0;
  @Input() href: string | undefined;
  @Input() routerLink: string | undefined;
}
