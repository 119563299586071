import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import { TextInputDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

let nextId = 1;
@Component({
  selector: 'dgx-dfb-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TooltipService],
})
export class InputComponent
  extends FormElementBaseComponent
  implements OnInit, ControlValueAccessor {
  @Input() field!: TextInputDef;
  @Input() validate = false;
  @HostBinding('class.hide-tick-icon') @Input() hideValidTickIcon = false;

  valueSubject = new BehaviorSubject<unknown>(undefined);
  value$ = this.valueSubject.asObservable();

  blurred = false;
  tooltipName = 'tooltip';
  precision = '2';
  id = `input-${nextId++}`;
  disabled = false;

  get isNumberType() {
    return this.field?.type === 'number';
  }

  get type() {
    return this.field?.type || 'text';
  }

  get maxLength() {
    return this.field?.maxlength ? this.field?.maxlength : null;
  }

  constructor(
    @Optional() public ngControl: NgControl,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl);
  }
  ngOnInit() {
    super.ngOnInit();
    if (this.ngControl?.control?.touched) {
      this.blurred = true;
      this.updateErrorMessage();
    }
  }

  onChange(event: { target: { value: string } }): void {
    const val = event.target.value || '';
    if (this.field?.maxlength !== undefined && this.blurred) {
      const maxLength = this.field?.maxlength;
      if (val.toString().length > maxLength) {
        this.emitAndWrite(val.toString().slice(0, maxLength), false);
        return;
      }
      this.emitAndWrite(val, false);
    } else if (this.blurred) {
      this.emitAndWrite(val, false);
    }
  }

  onBlur(event: { target: { value: string } }): void {
    if (!this.blurred) {
      this.blurred = true;
      this.onChange(event);
    }
    this.emitAnalyticsData(event.target.value);

    if (this.field?.onBlur) {
      this.field.onBlur(event.target.value, this.ngControl.control);
    }
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(val: string) {
    this.emitValue(val);
  }

  emitValue(val: string) {
    this.valueSubject.next(val);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  private emitAndWrite(val: string, isWrite: boolean): void {
    this.emitValue(val);
    super.writeValue(val, isWrite);
  }

  onKeyDown(event: { key: string }) {
    if (event.key === 'Enter') {
      this.blurred = true;
    }
  }
}
