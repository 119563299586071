import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import { YearDateDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

let nextId = 1;

@UntilDestroy()
@Component({
  selector: 'dgx-dfb-year-date',
  templateUrl: './year-date.component.html',
  styleUrls: ['./year-date.component.scss'],
  providers: [TooltipService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearDateComponent
  extends FormElementBaseComponent
  implements OnChanges, OnInit, ControlValueAccessor {
  @Input() field!: YearDateDef;
  id = `year-date-${nextId++}`;

  value: { month: number | undefined; year: number | undefined } = {
    month: undefined,
    year: undefined,
  };
  disabled = false;
  tooltipName = 'tooltip';

  constructor(
    @Optional() public ngControl: NgControl,
    protected formGroupDir: FormGroupDirective,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  initValue() {
    this.value = this.value
      ? this.value
      : {
          month: undefined,
          year: undefined,
        };
  }

  saveYear(event: Event, val: string) {
    const option = this.field.values.find((v) => v.label === val);
    this.value.year = option?.year;
    this.value.month = option?.month;
    super.writeValue(this.value, false);
    this.emitAnalyticsData(this.value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }
}
