import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { TooltipService } from '../../_shared/directives/tooltip/tooltip.service';
import { TextAreaDef } from '../../_shared/interfaces/dynamic-formbuilder.interface';
import { FormElementBaseComponent } from '../form-element-base.component';

let nextId = 1;

@Component({
  selector: 'dgx-dfb-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TooltipService],
})
export class TextAreaComponent
  extends FormElementBaseComponent
  implements OnInit, ControlValueAccessor, OnChanges {
  @Input() field!: TextAreaDef;
  @Input() validate = false;
  @HostBinding('class.hide-tick-icon') @Input() hideValidTickIcon = false;

  blurred = false;
  tooltipName = 'tooltip';
  id = `textarea-${nextId++}`;
  disabled = false;
  charsLeft = 0;

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit() {
    if (this.ngControl?.control?.touched) {
      this.blurred = true;
      this.updateErrorMessage();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.field && this.field?.maxlength) {
      const maxLength = this.field?.maxlength;
      //todo: this should work but for some reason is not doing
      // const initialValue = this.field?.initialValue || '';
      const initialValue = '';
      this.charsLeft = maxLength - initialValue.length;
    }
  }

  private trimValue(value: string) {
    const maxLength = this.field?.maxlength;
    if (maxLength !== undefined && value.toString().length > maxLength) {
      return value.toString().slice(0, maxLength);
    }
    return value;
  }

  onChange(event: { target: { value: string } }): void {
    const val = this.trimValue(event.target.value || '');
    if (this.field?.maxlength !== undefined) {
      const maxLength = this.field?.maxlength;
      this.charsLeft = maxLength - val.length;
    }
    if (this.blurred) {
      this.emitAndWrite(this.trimValue(val), false);
    }
  }

  onBlur(event: { target: { value: string } }): void {
    if (!this.blurred) {
      this.blurred = true;
      this.onChange(event);
    }
    this.emitAnalyticsData(event.target.value);

    if (this.field?.onBlur) {
      this.field.onBlur(event.target.value, this.ngControl.control);
    }
  }

  registerOnChange(fn: (val: unknown) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private emitAndWrite(val: string, isWrite: boolean): void {
    super.writeValue(val, isWrite);
  }
}
