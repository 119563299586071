import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RUNTIME_CONFIG } from '@common/util-foundation';
import { AppModule } from './app/app.module';
import { environment } from '@sales/util-environment';

/*
  This code fetches the runtime config that is changed per deployed environment by CI Pipeline
  and written into the file config.json. Once the runtime config is fetched, we provide it as a token to be used by the App
*/

const configXhr = new XMLHttpRequest();
configXhr.open('GET', environment.configPath);
configXhr.send();
configXhr.onreadystatechange = function () {
  if (this.status === 200 && this.readyState === 4) {
    const config = JSON.parse(configXhr.responseText);
    if (environment.production) {
      enableProdMode();
    }
    platformBrowserDynamic([
      {
        provide: RUNTIME_CONFIG,
        useValue: config,
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  }
};
