import { NgModule } from '@angular/core';
import { cmsDataUrl } from '@common/data-access-shared';
import { SalesFeatureShellModule } from '@sales/feature-shell';
import { environment } from '@sales/util-environment';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { AppComponent } from './app.component';
import { COMPONENT_MAPPING } from './cms-config/cms-component-mapping.config';
import { gaTagsConfig } from './ga-tags-config/ga-tags-config';
// Test PR [OPS-4555]
@NgModule({
  declarations: [AppComponent],
  imports: [
    SalesFeatureShellModule.forRoot({
      componentMapping: COMPONENT_MAPPING,
      gaTagsConfig,
      cmsDataUrl,
    }),
    ScullyLibModule,
  ],
  providers: [
    { provide: 'isDevelopmentEnvironment', useValue: !environment.production }, // to enable debug logger
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
