import { Inject, Injectable } from '@angular/core';
import { FeatureConfigFacade } from '@common/data-access-feature-config';
import {
  ENVIRONMENT,
  mapToLatestFrom,
  RUNTIME_CONFIG,
} from '@common/util-foundation';
import {
  CmsConfig,
  ConfigAdapter,
  Environment,
  RuntimeConfig,
  SidedoorConfig,
} from '@common/util-models';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable()
export class CmsConfigAdapter implements ConfigAdapter {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    @Inject(RUNTIME_CONFIG) private runtimeConfig: RuntimeConfig,
    private readonly featureConfigFacade: FeatureConfigFacade
  ) {}

  getConfig(): Observable<{ cms_config: CmsConfig }> {
    return this.featureConfigFacade.remoteStateLoaded$.pipe(
      first(),
      mapToLatestFrom(this.featureConfigFacade.sidedoor$(false)),
      map((sidedoorConfig?: SidedoorConfig) => ({
        cms_config: {
          ...this.environment.cms_config,
          ...(sidedoorConfig?.cms ?? {}),
          damRawUrl: this.runtimeConfig.cmsDamRawUrl,
          contentAppBase: this.runtimeConfig.cmsContentAppBase,
          restUrlBase: this.runtimeConfig.cmsRestUrlBase,
          templateDefinitionBase: this.runtimeConfig.cmsTemplateDefinitionBase,
        },
      }))
    );
  }
}
