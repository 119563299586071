import { Component, OnDestroy } from '@angular/core';
import {
  NavigationError,
  NavigationStart,
  Router,
  Event,
} from '@angular/router';
import { BuildConfigService, ErrorService } from '@common/util-foundation';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'sales-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'sales';
  private subscriptions = new Subscription();

  popStateNavigation$ = this.router.events.pipe(
    filter(
      (allEvents) =>
        !!(allEvents instanceof NavigationStart) &&
        allEvents.navigationTrigger === 'popstate'
    )
  );

  navigationError$ = this.router.events.pipe(
    filter((allEvents) => !!(allEvents instanceof NavigationError))
  );

  firstNavigation$ = this.router.events.pipe(
    filter((allEvents) => !!(allEvents instanceof NavigationStart)),
    take(1)
  );

  constructor(
    private buildConfig: BuildConfigService,
    private errorService: ErrorService,
    private router: Router
  ) {
    this.subscriptions.add(
      this.navigationError$.subscribe(() => {
        this.errorService.handleError();
      })
    );
    this.firstNavigation$.subscribe((event: Event) =>
      this.preventRefresh(event as NavigationStart)
    );
  }

  //prevent refresh on (or directly loading) anything other than landing pages
  private preventRefresh(event: NavigationStart) {
    const [baseUrl] = event.url.split('?', 2);
    if (this.buildConfig.config.validLandingPages?.includes(baseUrl)) {
      return;
    }
    this.router.navigateByUrl(this.buildConfig.config.applianceDetailsPage);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
