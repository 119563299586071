/// <reference types="resize-observer-browser" />
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { PanelToggleButtonComponent } from '../panel-toggle-button/panel-toggle-button.component';
import {
  ExpandStatus,
  PanelBodyComponent,
} from '../panel-body/panel-body.component';
import { PanelSummaryComponent } from '../panel-summary/panel-summary.component';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'ui-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent
  implements AfterContentInit, AfterViewInit, OnInit, OnDestroy, OnChanges {
  @Input() initialExpanded = true;
  //todo: implement support for top?
  @Input() sticky: false | 'bottom' = false;
  @Input() stickyAutoExpand = false;
  /**
   * Note that these variant names may change
   */
  @Input() variant:
    | 'normal'
    | 'highlight'
    | 'highlight-primary'
    | 'highlight-advertising'
    | 'highlight-feedback'
    | 'highlight-info'
    | 'default' = 'normal';
  isExpanded = false;
  isAnimating = false;
  isCurrentlySticky = false;
  private stickyObserver: IntersectionObserver | null = null;
  private resizeObserver: ResizeObserver | null = null;
  @ContentChild(PanelToggleButtonComponent)
  toggleButton!: PanelToggleButtonComponent;
  @ContentChild(PanelBodyComponent) body!: PanelBodyComponent;
  @ContentChild(PanelSummaryComponent) summary!: PanelSummaryComponent;
  @ViewChild('stickyChecker') stickyChecker!: ElementRef;

  @HostBinding('class.panel--sticky-bottom') get stickyBottom() {
    return this.sticky === 'bottom';
  }

  @HostBinding('class.panel--is-sticky') get isSticky() {
    return this.isCurrentlySticky;
  }

  @Output() expandedChange: EventEmitter<boolean> = new EventEmitter();

  private subscriptions = new Subscription();

  constructor(private hostElement: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (this.stickyObserver) {
      this.stickyObserver.unobserve(this.stickyChecker.nativeElement);
    }
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.hostElement.nativeElement);
    }
  }

  ngOnInit() {
    this.isExpanded = this.initialExpanded;
  }

  ngAfterContentInit() {
    this.propagateExpandedState();
    //todo: handle if toggleButton is added/removed later (e.g. via *ngIf)
    if (this.toggleButton) {
      this.subscriptions.add(
        this.toggleButton.toggle.subscribe((expanded: boolean) => {
          this.isExpanded = expanded;
          this.propagateExpandedState();
        })
      );
    }
    if (this.body) {
      this.subscriptions.add(
        this.body.toggleExpandAnimation.subscribe((state: ExpandStatus) => {
          this.isAnimating = state === 'expanding' || state === 'collapsing';
        })
      );
    }
  }

  expand() {
    this.isExpanded = true;
    this.propagateExpandedState();
  }

  collapse() {
    if (!this.isExpanded) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      this.body.toggleExpandAnimation
        .pipe(
          filter((state: ExpandStatus) => state === 'collapsed'),
          take(1)
        )
        .subscribe(() => {
          resolve();
        });
      this.isExpanded = false;
      this.propagateExpandedState();
    });
  }

  ngAfterViewInit() {
    if (this.sticky) {
      this.isCurrentlySticky = true;
    }
    if (this.stickyAutoExpand && this.stickyChecker) {
      this.stickyObserver = new IntersectionObserver(
        ([e]) => {
          if (this.isAnimating) {
            // animations cause temporary change in visibility
            // so we ignore everything while this happens
            return;
          }
          const aboveViewport = e.boundingClientRect.top < 0;
          if (aboveViewport) {
            //if the user has scrolled past it we do nothing
            return;
          }
          if (!e.isIntersecting) {
            this.collapse().then(() => {
              this.isCurrentlySticky = true;
            });
          } else {
            this.isCurrentlySticky = false;
            this.expand();
          }
        },
        { threshold: [0] }
      );
      this.stickyObserver.observe(this.stickyChecker.nativeElement);
      //todo: possibly use ng-resize-observer instead
      this.resizeObserver = new ResizeObserver(() =>
        this.syncStickyObserverTop()
      );
      this.resizeObserver.observe(this.hostElement.nativeElement);
      this.syncStickyObserverTop();
    }
  }

  private syncStickyObserverTop() {
    const hostEl = this.hostElement.nativeElement;
    const innerEl = hostEl.querySelector('.panel__inner');
    const summaryEl = hostEl.querySelector('ui-panel-summary');
    const hostStyles = window.getComputedStyle(hostEl);
    const innerStyles = window.getComputedStyle(innerEl);

    const panelPaddingBottom = parseInt(
      hostStyles.getPropertyValue('padding-bottom'),
      10
    );
    const panelPaddingTop = parseInt(
      hostStyles.getPropertyValue('padding-top'),
      10
    );
    const innerPaddingBottom = parseInt(
      innerStyles.getPropertyValue('padding-bottom'),
      10
    );
    const innerBorderTop = parseInt(
      innerStyles.getPropertyValue('border-top'),
      10
    );
    const innerBorderBottom = parseInt(
      innerStyles.getPropertyValue('border-bottom'),
      10
    );
    const h =
      summaryEl.offsetTop +
      summaryEl.scrollHeight +
      panelPaddingBottom +
      panelPaddingTop +
      innerBorderTop +
      innerBorderBottom +
      innerPaddingBottom;
    this.stickyChecker.nativeElement.style.top = `${h + 1}px`;
  }

  private propagateExpandedState() {
    this.expandedChange.emit(this.isExpanded);
    if (this.toggleButton) {
      this.toggleButton.isExpanded = this.isExpanded;
    }
    if (this.body) {
      this.body.isExpanded = this.isExpanded;
    }
    if (this.summary) {
      this.summary.isExpanded = !this.isExpanded;
    }
    this.cd.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variant) {
      this.cd.markForCheck();
    }
  }
}
