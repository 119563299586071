export const isWithinMonthsFromCurrentYearMonth = (
  year: number,
  month: number,
  withinMonths: number
): boolean => {
  const enteredDate = Date.UTC(year, month - 1, 1, 0, 0, 0, 0);
  const currentDateMinusWithinMonths = new Date(Date.now());
  currentDateMinusWithinMonths.setUTCMonth(
    currentDateMinusWithinMonths.getUTCMonth() - withinMonths
  );
  currentDateMinusWithinMonths.setUTCDate(1);
  currentDateMinusWithinMonths.setUTCHours(0);
  currentDateMinusWithinMonths.setUTCMinutes(0);
  currentDateMinusWithinMonths.setUTCSeconds(0);
  currentDateMinusWithinMonths.setUTCMilliseconds(0);
  return enteredDate > currentDateMinusWithinMonths.valueOf();
};
