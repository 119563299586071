<div class="alert alert--{{ variant }} " role="alert">
  <div *ngIf="iconVisible" class="alert__icon-container">
    <svg
      class="alert__icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
    >
      <circle cx="30" cy="30" r="30" fill="#fff" />
      <path
        fill="currentColor"
        d="M30.217 13.874L14.66 40.807a.2.2 0 00.171.3l30.66-.018a.2.2 0 00.179-.3l-15.024-26.9a.246.246 0 00-.429 0zM13.828 40.785l.635.367-.635-.367zm32.075 1.771H14.487a1.473 1.473 0 01-1.055-.4 1.423 1.423 0 01-.239-1.734l16-27.7a1.435 1.435 0 011.243-.715 1.433 1.433 0 011.253.736l15.439 27.642a1.451 1.451 0 01.161 1.1 1.433 1.433 0 01-1.389 1.061z"
      />
      <path
        fill="currentColor"
        d="M30.328 32.749a1.467 1.467 0 01-1.467-1.467v-8.09a1.466 1.466 0 112.933 0v8.09a1.466 1.466 0 01-1.466 1.467z"
      />
      <path
        fill="currentColor"
        d="M31.871 35.692a1.541 1.541 0 11-1.541-1.541 1.541 1.541 0 011.541 1.541z"
      />
    </svg>
  </div>

  <div class="alert__content">
    <h4 *ngIf="heading" class="alert__heading">{{ heading }}</h4>
    <ng-content></ng-content>
  </div>
</div>
