import { ActionReducer } from '@ngrx/store';
import { QUOTES_FEATURE_KEY } from '@common/data-access-quotes';
import { CHECKOUT_FEATURE_KEY } from '@common/data-access-checkout';
import { LocalStorageConfig, localStorageSync } from 'ngrx-store-localstorage';
import { USERPROFILE_FEATURE_KEY } from '@common/data-access-user-profile';
import { PERSONAL_DETAILS_FEATURE_KEY } from '@common/data-access-personal-details';
import { SELECTPAYMENT_FEATURE_KEY } from '@common/data-access-select-payment';
import { APPLIANCE_DETAILS_FEATURE_KEY } from '@common/data-access-appliance-details';
import { CARD_PAYMENT_FEATURE_KEY } from '@common/data-access-card-payment';
import { NAVIGATION_FEATURE_KEY } from '@shared/data-access-navigation';

export function localStorageSyncReducer(
  reducer: ActionReducer<Record<string, unknown>>
): ActionReducer<Record<string, unknown>> {
  return localStorageSync({
    keys: [
      'auth',
      CHECKOUT_FEATURE_KEY,
      QUOTES_FEATURE_KEY,
      USERPROFILE_FEATURE_KEY,
      PERSONAL_DETAILS_FEATURE_KEY,
      SELECTPAYMENT_FEATURE_KEY,
      APPLIANCE_DETAILS_FEATURE_KEY,
      CARD_PAYMENT_FEATURE_KEY,
      NAVIGATION_FEATURE_KEY,
    ],
    storage: sessionStorage,
    rehydrate: true,
  } as LocalStorageConfig)(reducer);
}
