import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { RouterModule } from '@angular/router';

const components = [ButtonComponent, ButtonGroupComponent];
@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [...components, ButtonGroupComponent],
  exports: [...components],
})
export class ButtonComponentsModule {}
