import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import {
  CmsFormField,
  PlanDocumentsFormField,
} from '@domgen/dgx-fe-business-models';
import {
  DynamicFormbuilderService,
  FieldDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { combineLatest, of } from 'rxjs';
import { first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PlanDocumentsFormConfigService } from './plan-documents-form-config.service';
export interface PlanDocumentsFormState {
  formBuilderConfig: FieldDef[];
  cmsFormData: CmsFormField[];
  form: FormGroup;
  validate: boolean;
  hideTermsAndConditionField: boolean;
  displayPaperlessAs?: 'checkbox' | 'buttons';
  prefilledForm: boolean | null;
}

@Injectable()
export class PlanDocumentsFormStateService extends ComponentStore<PlanDocumentsFormState> {
  // Selectors
  readonly formBuilderConfig$ = this.select(
    (state: PlanDocumentsFormState) => state.formBuilderConfig
  );
  readonly formGroup$ = this.select(
    (state: PlanDocumentsFormState) => state.form
  );
  readonly validate$ = this.select(
    (state: PlanDocumentsFormState) => state.validate
  );
  readonly cmsFormData$ = this.select(
    (state: PlanDocumentsFormState) => state.cmsFormData
  );
  readonly prefilledForm$ = this.select(
    (state: PlanDocumentsFormState) => state.prefilledForm
  );
  readonly displayPaperlessAs$ = this.select(
    (state: PlanDocumentsFormState) => state?.displayPaperlessAs
  );

  readonly hideTermsAndConditionsField$ = this.select(
    (state: PlanDocumentsFormState) => state.hideTermsAndConditionField
  );

  readonly valueChanges$ = this.formGroup$.pipe(
    withLatestFrom(this.displayPaperlessAs$),
    switchMap(([formGroup, displayPaperlessAs]) =>
      this.dynamicFormBuilder
        .selectValidFormValue$<{
          [PlanDocumentsFormField.Paperless]: string | boolean;
          [PlanDocumentsFormField.TermsAndConditions]: string;
        }>(formGroup)
        .pipe(
          map((formValue) => {
            let paperless = false;
            if (
              displayPaperlessAs === 'checkbox' &&
              typeof formValue.paperless === 'boolean'
            ) {
              paperless = formValue.paperless;
            } else if (typeof formValue.paperless === 'string') {
              paperless =
                formValue.paperless?.toLocaleLowerCase() === 'yes'
                  ? true
                  : false;
            }
            return {
              paperless,
              termsAndConditions:
                String(
                  formValue.termsAndConditions || ''
                )?.toLocaleLowerCase() === 'true'
                  ? true
                  : false,
            };
          })
        )
    )
  );

  readonly setCmsFormData = this.updater(
    (state: PlanDocumentsFormState, value: CmsFormField[]) => ({
      ...state,
      cmsFormData: value,
    })
  );

  readonly setPrefilledFormData = this.updater(
    (state: PlanDocumentsFormState, value: boolean | null) => ({
      ...state,
      prefilledForm: value,
    })
  );

  readonly setDisplayPaperlessAs = this.updater(
    (state: PlanDocumentsFormState, value: 'checkbox' | 'buttons') => ({
      ...state,
      displayPaperlessAs: value,
    })
  );

  readonly hideTermsAndConditionField = this.updater(
    (state: PlanDocumentsFormState, value: boolean) => ({
      ...state,
      hideTermsAndConditionField: value,
    })
  );

  private fieldDefByTermsUpdater$ = combineLatest([
    this.hideTermsAndConditionsField$,
    this.cmsFormData$,
    this.displayPaperlessAs$,
  ]).pipe(
    withLatestFrom(this.prefilledForm$),
    map(([[hide, cmsFormData, displayPaperlessAs], prefilledForm]) => {
      if (!cmsFormData.length) {
        return [];
      }
      return hide
        ? this.formConfig.getOnlyPlanDocumentFormConfig(
            cmsFormData,
            prefilledForm,
            displayPaperlessAs
          )
        : this.formConfig.getFormbuilderConfig(cmsFormData, prefilledForm);
    })
  );

  readonly validate = this.updater(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state: PlanDocumentsFormState) => ({
      ...state,
      validate: true,
    })
  );

  private readonly cmsFieldDefByTermsUpdater = this.updater(
    (state: PlanDocumentsFormState, value: FieldDef[]) => ({
      ...state,
      formBuilderConfig: value,
    })
  )(this.fieldDefByTermsUpdater$);

  private formGroupUpdater$ = this.formBuilderConfig$.pipe(
    switchMap((formBuilderConfig) =>
      of(formBuilderConfig).pipe(
        map((conf) => this.dynamicFormBuilder.generateFormGroup(conf))
      )
    )
  );

  private readonly formGroupUpdater = this.updater(
    (state: PlanDocumentsFormState, value: FormGroup) => ({
      ...state,
      form: value,
    })
  )(this.formGroupUpdater$);

  readonly vm$ = this.select(
    this.formBuilderConfig$,
    this.formGroup$,
    this.validate$,
    (fieldDef, formGroup, validate) => ({
      fieldDef,
      formGroup,
      validate,
    })
  );

  constructor(
    private formConfig: PlanDocumentsFormConfigService,
    private dynamicFormBuilder: DynamicFormbuilderService
  ) {
    super({
      formBuilderConfig: [],
      cmsFormData: [],
      form: new FormGroup({}),
      validate: false,
      hideTermsAndConditionField: false,
      prefilledForm: null,
    });
  }

  patchFormValue(
    formValue: Partial<Record<PlanDocumentsFormField, boolean>>
  ): void {
    this.formGroup$.pipe(first()).subscribe((formGroup: FormGroup) => {
      formGroup.patchValue(formValue);
    });
  }
}
