import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  filter,
  map,
  switchAll,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  Address,
  AddressItem,
  AddressListDef,
  ListBoxDef,
} from '../../_shared/interfaces';
import { AddressListStateHelpersService } from './address-list-state-helpers.service';

const addressFormControlName = 'address';

export interface AddressListState {
  addressForm: FormGroup;
  listboxControl: FormControl;
  fieldDef?: AddressListDef;
  controlName: string;
  listBoxDef?: ListBoxDef;
  optionsStream$: BehaviorSubject<AddressItem[]>;
  formControl?: AbstractControl;
}

export const initialState: AddressListState = {
  addressForm: new FormGroup({
    [addressFormControlName]: new FormControl(undefined),
  }),
  listboxControl: new FormControl(undefined),
  fieldDef: undefined,
  controlName: addressFormControlName,
  listBoxDef: undefined,
  optionsStream$: new BehaviorSubject<AddressItem[]>([]),
  formControl: undefined,
};

export interface AddressListStateVielModel {
  fieldDef: AddressListDef;
  addressForm: FormGroup;
  controlName: string;
  listboxControl: FormControl;
  listBoxDef?: ListBoxDef;
}

@Injectable()
export class AddressListStateService extends ComponentStore<AddressListState> {
  addressTextValue$ = new BehaviorSubject<string[]>([]);

  // ************  Selectors **********

  private readonly fieldDef$ = this.select(
    (state: AddressListState) => state.fieldDef
  ).pipe(filter((fieldDef) => !!fieldDef)) as Observable<AddressListDef>;

  private readonly listBoxDef$ = this.select(
    (state: AddressListState) => state.listBoxDef
  ).pipe(filter((listBoxDef) => !!listBoxDef));

  private readonly postCode$ = this.select(
    (state: AddressListState) => state.fieldDef
  ).pipe(
    filter((fieldDef) => !!fieldDef),
    map((fieldDef) => fieldDef?.initialValue?.postcode),
    filter((postCode) => !!postCode)
  ) as Observable<string>;

  private readonly addressItems$ = this.select(
    (state: AddressListState) => state.optionsStream$
  );

  private readonly formControl$ = this.select(
    (state: AddressListState) => state.formControl
  ).pipe(filter((formControl) => !!formControl));

  private readonly addressForm$ = this.select(
    (state: AddressListState) => state.addressForm
  ).pipe(filter((addressForm) => !!addressForm));

  private readonly controlName$ = this.select(
    (state: AddressListState) => state.controlName
  ).pipe(filter((controlName) => !!controlName));

  private readonly listboxControl$ = this.select(
    (state: AddressListState) => state.listboxControl
  ).pipe(filter((listboxControl) => !!listboxControl));

  private readonly addressText$ = this.select(
    (state: AddressListState) => state.optionsStream$
  ).pipe(
    switchAll(),
    withLatestFrom(this.postCode$),
    filter(([, postCode]) => !!postCode),
    map(([addressItems, postCode]) =>
      addressItems.map(({ Text }) => `${Text}, ${postCode}`)
    )
  );

  // // ******** Public Updaters *********
  readonly setFieldDef = this.updater(
    (state: AddressListState, fieldDef: AddressListDef) => {
      return {
        ...state,
        fieldDef: {
          ...fieldDef,
          label: this.addressListStateHelpersService.updateLabel(fieldDef),
          hightlightedText: fieldDef?.initialValue?.postcode,
          optionsStream$: this.addressTextValue$,
        },
      };
    }
  );

  readonly setFormControl = this.updater(
    (state: AddressListState, formControl: AbstractControl) => {
      return {
        ...state,
        formControl,
      };
    }
  );

  readonly clearSelection = this.updater((state: AddressListState) => {
    state.listboxControl?.reset();

    const address = this.addressListStateHelpersService.emptyForm(
      state.fieldDef?.initialValue
    );
    state.formControl?.setValue(address);
    state.addressForm.get(addressFormControlName)?.setValue(address);
    return {
      ...state,
    };
  });

  // // ******** Private Updaters *********
  private readonly initAddressForm = this.updater(
    (state: AddressListState, fieldDef: AddressListDef) => {
      const address = this.addressListStateHelpersService.emptyForm(
        fieldDef.initialValue
      );

      this.addressFormUpdater(address);
      return {
        ...state,
      };
    }
  )(this.fieldDef$);

  private readonly addressFormUpdater = this.updater(
    (state: AddressListState, address: Address | undefined) => {
      state.addressForm.get(addressFormControlName)?.setValue(address);
      state.formControl?.setValue(address);
      return {
        ...state,
      };
    }
  );

  private readonly listBoxDefUpdater = this.updater(
    (state: AddressListState, fieldDef: AddressListDef) => {
      if (fieldDef.validators) {
        const validators = fieldDef.validators as ValidatorFn[];
        state.listboxControl.setValidators(validators);
        state.listboxControl.updateValueAndValidity();
      }
      return {
        ...state,
        listBoxDef: this.addressListStateHelpersService.getListBoxDef(fieldDef),
      };
    }
  )(this.fieldDef$);

  // // ********* Public Effects *********
  readonly setSelectedAddress = this.effect((payload$: Observable<number>) =>
    payload$.pipe(
      withLatestFrom(this.addressItems$.pipe(switchAll())),
      map(([payload, addressItems]) =>
        this.addressListStateHelpersService.getAddressIdFromAddressItemList(
          addressItems,
          payload
        )
      ),
      switchMap((addressId: string) =>
        this.addressListStateHelpersService.getRetrieveAddress(addressId)
      ),
      tap((address: Address) => this.addressFormUpdater(address))
    )
  );

  readonly addressFormValueChanges$ = this.addressForm$.pipe(
    map((formGroup) => formGroup.get(addressFormControlName) as FormControl),
    switchMap((addressFormControl) => addressFormControl.valueChanges),
    withLatestFrom(this.postCode$),
    map(([address, postcode]) => ({
      ...address,
      postcode,
    }))
  ) as Observable<Address>;

  // // ********* Private Effects *********
  private readonly searchAddresses$ = this.effect(
    (postCode$: Observable<string>) =>
      postCode$.pipe(
        switchMap((postcode) =>
          this.addressListStateHelpersService.getAddressByPostcode(postcode)
        ),
        withLatestFrom(this.addressItems$, this.postCode$),
        tap(([addresses, addressItems$, postcode]) => {
          addressItems$.next(addresses);
          const addressText = addresses.map(
            ({ Text }) => `${Text}, ${postcode}`
          );
          this.addressTextValue$.next(addressText);
        })
      )
  )(this.postCode$);

  // ************ Selectors **********
  readonly vm$: Observable<AddressListStateVielModel> = this.select(
    combineLatest([
      this.fieldDef$,
      this.addressForm$,
      this.controlName$,
      this.listboxControl$,
      this.listBoxDef$,
    ]),
    ([fieldDef, addressForm, controlName, listboxControl, listBoxDef]) => ({
      fieldDef,
      addressForm,
      controlName,
      listboxControl,
      listBoxDef,
    })
  );

  constructor(
    private addressListStateHelpersService: AddressListStateHelpersService
  ) {
    super(initialState);
  }
}
