import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {
  WorldPayOrderStatus,
  WorldPayResponse,
} from '../interfaces/world-pay-card-payment.types';
import { FormServicesModule } from './form-services.module';

enum AddressType {
  Residential = 'Residential',
  Commercial = 'Commercial',
}
export interface ValidatorFn {
  (control: AbstractControl): ValidationErrors | null;
}
@Injectable({
  providedIn: FormServicesModule,
})
export class CustomValidatorService {
  postCodeCustomValidators$: BehaviorSubject<ValidatorFn> = new BehaviorSubject<ValidatorFn>(
    () => null
  );

  creditCardValidator(control: FormControl) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (
      control.value.match(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
      )
    ) {
      return null;
    } else {
      return { invalidCreditCard: true };
    }
  }

  passwordValidator(control: FormControl) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }

  mobileNumberValidator(control: FormControl): ValidationErrors | null {
    if (control.value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/)) {
      return null;
    } else {
      return { invalidMobileNumber: true };
    }
  }

  emailValidator(control: FormControl): ValidationErrors | null {
    // using ajv-formats to match rule used on backend
    // https://github.com/ajv-validator/ajv-formats/blob/8827acb8d820c9e44417738a478989df5152a397/src/formats.ts#L58
    const ajvRule = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

    // also checking myAccountRule to reduce problems later with autoregistration
    // https://github.com/domgen/athome_myaccount_spa/blob/3b69b1c782408055a178223a5729ad7ebeda8bf7/apps/my-account/src/app/login/common/regex-form-validations/regex.ts#L8
    const myAccountRule = /^[A-Za-z0-9][A-Za-z0-9_.+-]*@(?!.*[_.+-]{2})[A-Za-z0-9][A-Za-z0-9_.+-]*$/;
    const myAccountMaxLen = 50;
    const myAccountMinLen = 10;

    const valLen = control.value.length;
    if (
      control.value.match(ajvRule) &&
      control.value.match(myAccountRule) &&
      valLen >= myAccountMinLen &&
      valLen <= myAccountMaxLen
    ) {
      return null;
    } else {
      return { email: true };
    }
  }

  nameValidator(control: FormControl): ValidationErrors | null {
    if (control.value.match(/^[A-Za-z]+([ A-Za-z'()-]+)*$/)) {
      return null;
    } else {
      return { invalidName: true };
    }
  }

  directDebitAccountNumberValidator(
    control: FormControl
  ): ValidationErrors | null {
    if (control.value.match(/^[0-9]{8}$/)) {
      return null;
    } else {
      return { invalidAccountNumber: true };
    }
  }

  directDebitSortCodeValidator(control: FormControl): ValidationErrors | null {
    if (control.value.match(/^[0-9]{6}$/)) {
      return null;
    } else {
      return { invalidSortCode: true };
    }
  }

  requiredYear(control: AbstractControl): ValidationErrors | null {
    if (!control.value?.year) {
      return { required: true };
    }
    return null;
  }

  // Check whether selected month and year are less then
  // maximum allowed period ago (8 years and 11 months/ ConfigAge)
  // maximum allowed period ago (96 months=8 years) or provided value
  applianceAgeValidator(maxMonthValue: number | null = null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value?.month || !control.value?.year) {
        return null; // OK - can't fail validation if one or both values are missing
      }
      const now = new Date();
      const maxMonths = maxMonthValue ? maxMonthValue : 96;
      const totalMonthsNow = now.getFullYear() * 12 + now.getMonth() + 1;
      const totalMonthsThen = control.value.year * 12 + control.value.month;
      const ageInMonths = totalMonthsNow - totalMonthsThen;
      if (ageInMonths < 0) {
        return {
          applianceTooNew: true, // can't accept dates in future
        };
      }
      if (ageInMonths > maxMonths) {
        return {
          applianceTooOld: true, // validation did not pass
        };
      }
      return null; // OK - selected date is within allowed range
    };
  }

  // Show a validation error when either month or year are not selected
  partialDateValidator(control: FormControl) {
    if (control.value?.month < 1 || control.value?.month > 12) {
      return {
        invalidMonth: true,
      };
    } else if (
      control.value?.year < 2000 ||
      control.value?.year > new Date().getFullYear()
    ) {
      return {
        invalidYear: true,
      };
    } else if (!control.value?.month && control.value?.year) {
      return {
        monthNotSelected: true,
      };
    } else if (!control.value?.year && control.value?.month) {
      return {
        yearNotSelected: true,
      };
    } else {
      return null;
    }
  }

  residentialAddressValidator(control: FormControl): ValidationErrors | null {
    if (control?.value?.type === AddressType.Commercial) {
      return { invalidResidentialAddress: true };
    } else {
      return null;
    }
  }

  successfulCardPaymentValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const value = control.value as WorldPayResponse;
    if (value?.order?.status === WorldPayOrderStatus.Success) {
      return null;
    }

    return { unsuccessFullCardPayment: true };
  }

  requiredYes(control: AbstractControl): ValidationErrors | null {
    if (control.value !== 'Yes') {
      return { requiredYes: true };
    }
    return null;
  }

  requiredNo(control: AbstractControl): ValidationErrors | null {
    if (control.value !== 'No') {
      return { requiredNo: true };
    }
    return null;
  }
}
