import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { applianceIsWhiteGoods } from '@common/util-foundation';
import {
  BasketItem,
  BasketItemPaymentOption,
  BasketItemQuoteDetails,
  PaymentType,
} from '@common/util-models';

@Component({
  selector: 'sales-coverage-banner',
  templateUrl: './coverage-banner.component.html',
  styleUrls: ['./coverage-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageBannerComponent implements OnChanges {
  @Input() data: BasketItem | undefined = undefined;
  @Input() loading = false;
  @Input() discountPercentage: number | undefined = undefined;
  @Output() analytics = new EventEmitter<BasketItem>();

  brandName = '';
  applianceName = '';
  applianceIsWhiteGoods = false;
  price: number | undefined;
  monthlyPrice: number | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.data || !this.data?.data || this.loading) {
      return;
    }

    this.brandName = this.data.data.applianceDetails?.brandName;
    this.applianceName = this.data.data.applianceDetails?.applianceName;
    this.applianceIsWhiteGoods = applianceIsWhiteGoods(
      this.data.data.applianceDetails
    );
    this.setPrice();
    this.setMonthlyPrice();
    this.analytics.emit(this.data);
  }

  private setPrice(): void {
    const cardPaymentOptions = this.paymentOptions
      .filter(
        (paymentOption: BasketItemPaymentOption) =>
          paymentOption.paymentType === PaymentType.Card
      )
      .sort(
        (optionA: BasketItemPaymentOption, optionB: BasketItemPaymentOption) =>
          optionA.fee - optionB.fee
      );

    this.price = cardPaymentOptions.length
      ? cardPaymentOptions[0].fee
      : undefined;
  }

  private setMonthlyPrice(): void {
    const directDebitPaymentOptions = this.paymentOptions
      .filter(
        (paymentOption: BasketItemPaymentOption) =>
          paymentOption.paymentType === PaymentType.DirectDebit
      )
      .sort(
        (optionA: BasketItemPaymentOption, optionB: BasketItemPaymentOption) =>
          optionA.subsequentPayment - optionB.subsequentPayment
      );
    this.monthlyPrice = directDebitPaymentOptions.length
      ? directDebitPaymentOptions[0].subsequentPayment
      : undefined;
  }

  private get paymentOptions(): BasketItemPaymentOption[] {
    return (
      this.data?.data.quotes.reduce(
        (result: BasketItemPaymentOption[], quote: BasketItemQuoteDetails) => [
          ...result,
          ...Object.values(quote.paymentOptions),
        ],
        []
      ) ?? []
    );
  }
}
