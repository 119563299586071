<div class="go-to-my-account-section">
  <div class="md-row sm-col">
    <!-- Banner -->
    <div class="xs-12--none lg-4--none xl-6--none banner-container">
      <img
        class="banner"
        alt="{{ content?.title }}"
        src="./assets/images/img-couple-viewing-laptop-440x344.jpg"
      />
    </div>
    <div class="xs-12--none lg-8--none xl-6--none p-2">
      <div class="text-container">
        <!-- Outer wrapper -->
        <div *ngIf="content">
          <div class="title">
            {{ content.title }}
          </div>
          <div class="md-row summary">
            {{ content.summary }}
          </div>
        </div>
        <div>
          <ui-button-group>
            <ui-button variant="secondary" (click)="redirectToMyAccount()">
              Go to My Account
            </ui-button>
          </ui-button-group>
        </div>
      </div>
    </div>
  </div>
</div>
