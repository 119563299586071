import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonDataAccessCardPaymentModule } from '@common/data-access-card-payment';
import { CommonDataAccessCheckoutModule } from '@common/data-access-checkout';
import { CommonDataAccessCheckoutBasketModule } from '@common/data-access-checkout-basket';
import { CommonDataAccessCheckoutOrderConfirmationModule } from '@common/data-access-checkout-order-confirmation';
import { CommonDataAccessFeatureConfigModule } from '@common/data-access-feature-config';
import { CommonDataAccessGaTaggingModule } from '@common/data-access-ga-tagging';
import { CommonDataAccessPersonalDetailsModule } from '@common/data-access-personal-details';
import { CommonDataAccessSelectPaymentModule } from '@common/data-access-select-payment';
import { CommonDataAccessUserProfileModule } from '@common/data-access-user-profile';
import { FORM_BUILDER_CONFIG } from '@common/util-foundation';
import { SharedFeatureAuthenticationModule } from '@domgen/dgx-fe-auth';
import { SharedUiCmsModule } from '@domgen/dgx-fe-content-management';
import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
import { SharedDataAccessAuthModule } from '@shared/data-access-auth';
import { RecaptchaV3Module } from 'ng-recaptcha';
import { CMS_CONFIG } from '../tokens/cms-config.token';
import { authenticationModuleConfig } from './authentication-module-config.constant';

export const DefaultSalesDependencies = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  SharedUiCmsModule.forRoot(CMS_CONFIG),
  SharedUiDynamicFormBuilderModule.forRoot(FORM_BUILDER_CONFIG),
  SharedFeatureAuthenticationModule.forRoot(authenticationModuleConfig),
  CommonDataAccessCheckoutBasketModule,
  CommonDataAccessCheckoutModule,
  CommonDataAccessCheckoutOrderConfirmationModule,
  CommonDataAccessUserProfileModule,
  CommonDataAccessPersonalDetailsModule,
  CommonDataAccessSelectPaymentModule,
  CommonDataAccessCardPaymentModule,
  CommonDataAccessFeatureConfigModule,
  CommonDataAccessGaTaggingModule,
  SharedDataAccessAuthModule,
  RecaptchaV3Module,
];
