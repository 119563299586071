<div class="overlay md--hidden" *ngIf="isOpen" [@fadeInOut]></div>
<header
  data-hook="app-header"
  class="header xs--middle"
  [class.active]="isOpen"
>
  <div class="container">
    <button
      (click)="onToggleMenu()"
      [attr.aria-label]="
        isOpen ? 'Close navigation menu' : 'Open navigation menu'
      "
      [class.active]="isOpen"
      class="header__menu-button md--hidden"
      id="menu-button"
    ></button>
    <div class="container xs--middle header__nav-wrapper">
      <div class="header__menu flex-auto"></div>

      <a
        *ngIf="homeNavigationLink.routerPath; else externalHomeNavigationLink"
        (click)="onClick($event)"
        [attr.aria-label]="homeNavigationLink.label"
        [routerLink]="[homeNavigationLink.routerPath]"
        class="header__logo xs--middle flex-auto"
      >
        <img
          src="assets/images/dg-logo-colour.png"
          role="img"
          alt="Domestic & General"
        />
      </a>

      <ng-template #externalHomeNavigationLink>
        <a
          (click)="onClick($event)"
          [attr.aria-label]="homeNavigationLink.label"
          [href]="homeNavigationLink.href"
          class="header__logo xs--middle flex-auto"
          target="_blank"
        >
          <img
            src="assets/images/dg-logo-colour.png"
            role="img"
            alt="Domestic & General"
          />
        </a>
      </ng-template>

      <nav class="container header__nav flex-auto">
        <ul class="xs--hidden xs--stack md-row" [class.active]="isOpen">
          <li
            *ngFor="
              let navigationLink of navigationLinks;
              trackBy: trackNavigationLink
            "
          >
            <ng-container
              [ngTemplateOutlet]="
                navigationLink.href
                  ? externalNavigationLink
                  : internalNavigationLink
              "
              [ngTemplateOutletContext]="{ navigationLink: navigationLink }"
            ></ng-container>
          </li>

          <li class="icon-my-account icon--xl with-icon is-hidden">
            <a
              href="/my-account"
              (click)="onClick($event)"
              class="link xs--middle"
              target="_blank"
              >My account</a
            >
          </li>
          <li class="icon-basket icon--xl is-hidden">
            <a
              href="/basket"
              (click)="onClick($event)"
              class="link xs--middle"
              target="_blank"
              aria-label="Basket"
            ></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>

<ng-template #internalNavigationLink let-navigationLink="navigationLink">
  <a
    [routerLink]="[navigationLink.routerPath]"
    (click)="onClick($event)"
    class="link xs--middle"
    data-automation="navigationLink"
  >
    {{ navigationLink.label }}
  </a>
</ng-template>

<ng-template #externalNavigationLink let-navigationLink="navigationLink">
  <a
    [href]="navigationLink.href"
    (click)="onClick($event)"
    class="link xs--middle"
    target="_blank"
    data-automation="navigationLink"
  >
    {{ navigationLink.label }}
  </a>
</ng-template>
