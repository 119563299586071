import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { ListItemComponent } from './list-item/list-item.component';

const components = [ListComponent, ListItemComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class ListComponentsModule {}
