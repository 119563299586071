import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplianceDetailsFormDataResolver } from '@common/data-access-appliance-details';
import { HasSelectedBasketItemsGuard } from '@common/data-access-quotes';
import { ErrorComponent } from '@common/ui-sales-components';
import { CmsPage } from '@common/util-models';
import { StepCompletionNavigationGuard } from '@shared/data-access-navigation';
import { QuoteCmsPageResolver } from './resolvers/quote-cms-page.resolver';
import { CardPaymentSetupResolver } from '@common/data-access-card-payment';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: '.html',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  {
    // TODO: add a guard
    path: 'quote',
    loadChildren: () =>
      import('@sales/feature-quote').then(
        (module) => module.SalesFeatureQuoteModule
      ),
    canActivate: [StepCompletionNavigationGuard, HasSelectedBasketItemsGuard],
    resolve: {
      cmsData: QuoteCmsPageResolver,
    },
    data: {
      cmsDataPage: CmsPage.Quote,
    },
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: 'quote.html',
    redirectTo: '/quote',
    pathMatch: 'full',
  },
  {
    path: 'products',
    loadChildren: () =>
      import('@sales/feature-appliance-details').then(
        (module) => module.SalesFeatureApplianceDetailsModule
      ),
    canActivate: [StepCompletionNavigationGuard],
    resolve: {
      quoteParamsParseResponse: ApplianceDetailsFormDataResolver,
    },
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: 'products.html',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  {
    path: 'checkout',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@sales/feature-checkout-landing').then(
            (m) => m.SalesFeatureCheckoutLandingModule
          ),
      },
      {
        path: 'personal-details',
        loadChildren: () =>
          import('@sales/feature-checkout-personal-details').then(
            (m) => m.SalesFeatureCheckoutPersonalDetailsModule
          ),
        resolve: {
          quoteData: QuoteCmsPageResolver,
        },
      },
      {
        path: 'logged-in',
        loadChildren: () =>
          import('@sales/feature-checkout-logged-in-user').then(
            (m) => m.SalesFeatureCheckoutLoggedInUserModule
          ),
        resolve: {
          quoteData: QuoteCmsPageResolver,
        },
      },
      {
        path: 'direct-debit-payment',
        loadChildren: () =>
          import('@sales/feature-checkout-direct-debit').then(
            (m) => m.SalesFeatureCheckoutDirectDebitModule
          ),
      },
      {
        path: 'order-confirmation',
        loadChildren: () =>
          import('@sales/feature-order-confirmation').then(
            (m) => m.SalesFeatureOrderConfirmationModule
          ),
      },
      {
        path: 'card-payment',
        loadChildren: () =>
          import('@sales/feature-checkout-card-payment').then(
            (m) => m.SalesFeatureCheckoutCardPaymentModule
          ),
        resolve: {
          setupCardPayment: CardPaymentSetupResolver,
        },
      },
      {
        path: 'select-payment',
        loadChildren: () =>
          import('@sales/feature-checkout-select-payment').then(
            (m) => m.SalesFeatureCheckoutSelectPaymentModule
          ),
      },
    ],
    canActivate: [StepCompletionNavigationGuard, HasSelectedBasketItemsGuard],
  },
  { path: 'error', data: { title: 'Error' }, component: ErrorComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class SalesFeatureShellRoutingModule {} // Main routes for application
