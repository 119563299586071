import { Component, HostBinding, Input } from '@angular/core';

export interface detailData {
  key: string;
  value: string | null | undefined | number;
}
@Component({
  selector: 'ui-panel-display-info-row',
  templateUrl: './panel-display-info-row.component.html',
  styleUrls: ['./panel-display-info-row.component.scss'],
})
export class PanelDisplayInfoRowComponent {
  @Input() public set direction(value: 'row' | 'column') {
    this._direction = `direction--${value}`;
  }
  @Input() data: detailData | undefined;
  @HostBinding('class') private _direction = 'direction--column';
  @HostBinding('class.detail-panel__row') someField = true;
}
