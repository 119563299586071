import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AnalyticsData,
  CoversExclusionTabs,
} from '@domgen/dgx-fe-business-models';

export const COVERS_EXCLUSIONS_CONTROL_NAME = 'CoversExclusions';
@Component({
  selector: 'dgx-uib-covers-exclusions',
  templateUrl: './covers-exclusions.component.html',
  styleUrls: ['./covers-exclusions.component.scss'],
})
export class CoversExclusionsComponent {
  @Input() displayAs: 'tabs' | 'stacked' = 'tabs';
  @Input() customTickIcon = ''; //svg path for custom ICON
  @Input() customCrossIcon = '';

  @Input() included: string[] = [];
  @Input() excluded: string[] = [];

  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  handleTabSelection({ index }: { index: number }) {
    index === 0
      ? this.handleWhatsIncludedClick()
      : this.handleWhatsExcludedClick();
  }

  handleWhatsExcludedClick() {
    this.analytics.emit({
      controlName: COVERS_EXCLUSIONS_CONTROL_NAME,
      value: CoversExclusionTabs.WhatsExcluded,
    });
  }

  handleWhatsIncludedClick() {
    this.analytics.emit({
      controlName: COVERS_EXCLUSIONS_CONTROL_NAME,
      value: CoversExclusionTabs.WhatsIncluded,
    });
  }
}
