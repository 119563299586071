import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDialogData } from '../../models/alert-dialog-data.interface';

@Component({
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent {
  public body: string[] | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
    private matDialogRef: MatDialogRef<AlertDialogComponent>
  ) {
    if (data?.body) {
      this.body = Array.isArray(data.body) ? data.body : [data.body];
    }
  }

  public onPrimaryClick(): void {
    this.matDialogRef.close();

    if (this.data.primaryCta.click) {
      this.data.primaryCta.click();
    }
  }

  public onSecondaryClick(): void {
    this.matDialogRef.close();

    if (this.data.secondaryCta?.click) {
      this.data.secondaryCta?.click();
    }
  }
}
