<div class="tabs">
  <div class="tabs__nav">
    <button
      *ngFor="let tab of tabs; index as i"
      class="tab"
      [class.tab--active]="tab.active"
      (click)="selectTab(tab, $event, i)"
    >
      {{ tab.title }}
    </button>
  </div>
  <ng-content></ng-content>
</div>
