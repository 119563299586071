import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ui-panel-content-row',
  templateUrl: './panel-content-row.component.html',
  styleUrls: ['./panel-content-row.component.scss'],
})
export class PanelContentRowComponent {
  @HostBinding('class.detail-panel__row') rowCls = true;
}
