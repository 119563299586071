<ng-container *ngIf="vm$ | async as vm">
  <div class="address-lookup">
    <div class="address-lookup__title-section">
      <div class="address-lookup__title">Your address</div>
      <div class="address-lookup__description">
        Please provide your home address before continuing to payment.
      </div>
    </div>

    <div class="address-lookup__tabs">
      <div class="address-lookup__mode-section">
        <button
          class="address-lookup__mode-button"
          [ngClass]="{
            'address-lookup__mode-button--active': vm.selectionMode === 'lookup'
          }"
          (click)="setSelectionMode($any('lookup'), $event)"
        >
          Find address
        </button>
        <button
          class="address-lookup__mode-button"
          [ngClass]="{
            'address-lookup__mode-button--active': vm.selectionMode === 'manual'
          }"
          (click)="setSelectionMode($any('manual'), $event)"
        >
          Manual address
        </button>
      </div>

      <div
        class="address-lookup__lookup-mode"
        *ngIf="vm.selectionMode === 'lookup'"
      >
        <dgx-dfb-type-ahead
          *ngIf="vm.typeAheadDef"
          [field]="vm.typeAheadDef"
          [validate]="vm.validate"
          [typeInput]="vm.typeAheadValueOverride"
          (value)="handleEnteredInput($event)"
          [formControl]="vm.typeAheadFormControl"
        >
        </dgx-dfb-type-ahead>
        <dgx-dfb-error *ngIf="vm.errorMessage" [errorMessage]="vm.errorMessage">
        </dgx-dfb-error>
      </div>

      <div
        class="address-lookup__manual-mode"
        *ngIf="vm.selectionMode === 'manual'"
      >
        <ng-container [formGroup]="vm.addressForm">
          <dgx-dfb-manual-address-entry
            [fieldDef]="vm.fieldDef"
            [formControlName]="vm.addressFormControlName"
            [validateForm]="vm.validate"
          ></dgx-dfb-manual-address-entry>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
