<div class="coverage-banner">
  <ng-container
    [ngTemplateOutlet]="loading || !data ? loadingCoverage : coverage"
  ></ng-container>
</div>

<ng-template #coverage>
  <div class="coverage-banner__row lg-row md-row sm--stack xs--stack">
    <ng-container [ngTemplateOutlet]="coverageIcon"></ng-container>

    <p class="coverage-banner__content">
      We can protect your
      {{ brandName }}
      {{ applianceName }}
      for
      <strong>
        <ng-container *ngIf="monthlyPrice">
          £{{ monthlyPrice | number: '1.2-2' }} a month
        </ng-container>
        <ng-container *ngIf="!monthlyPrice && price">
          £{{ price | number: '1.2-2' }}
        </ng-container>
      </strong>
    </p>
  </div>
  <div *ngIf="applianceIsWhiteGoods" class="coverage-banner__row">
    <ul class="coverage-banner__benefits-list">
      <li
        *ngIf="!!discountPercentage"
        class="coverage-banner__benefits-list-item"
      >
        Includes {{ discountPercentage }}% discount
      </li>
      <li class="coverage-banner__benefits-list-item">
        Accidental damage cover
      </li>
      <li class="coverage-banner__benefits-list-item">Unlimited repairs</li>
      <li class="coverage-banner__benefits-list-item">
        If we can’t fix it, we will replace it
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #loadingCoverage>
  <div class="coverage-banner__row lg-row md-row sm--stack xs--stack">
    <ng-container [ngTemplateOutlet]="coverageIcon"></ng-container>
    <p class="coverage-banner__content">
      <strong>Fetching your quote</strong>
    </p>
  </div>
</ng-template>

<ng-template #coverageIcon>
  <div class="coverage-banner__icon-container">
    <svg
      [ngClass]="{ 'coverage-banner__icon--active': !(loading || !data) }"
      class="coverage-banner__icon coverage-banner__icon--shield"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0.361 -0.019)">
        <g transform="translate(14.135 12.631)">
          <path
            fill="var(--primary-500)"
            d="M16.9,21.6l-0.7,0.7l-2.5,2.5l-2.5-2.5l-0.7-0.7l-3.4-3.4l2.5-2.6L13,19l0.7,0.7l0.7-0.7l7.3-7.3l2.5,2.5
        L16.9,21.6L16.9,21.6z"
          />
          <path
            fill="var(--primary-500)"
            d="M29.9,16.4c0,8.4-5.9,15.7-14.2,17.3C7.4,32.1,1.5,24.8,1.5,16.4V5.1c4.6,2.7,10.4,1.6,13.7-2.6
        c0.2-0.2,0.4-0.5,0.5-0.7C15.9,2,16,2.2,16.2,2.5c3.3,4.2,9.1,5.3,13.7,2.6L29.9,16.4L29.9,16.4z M29.9,3.3c-4,3-9.7,2.1-12.6-1.9
        c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.7-1-1.1-1.6-0.8c-0.4,0.1-0.6,0.4-0.8,0.7C11.8,5,6.2,6.3,2,3.6C1.3,3.1,0.6,2.6,0,1.9v14.5
        c0,9.2,6.6,17.1,15.7,18.8v0h0h0v0c9.1-1.7,15.7-9.6,15.7-18.8V1.9C30.9,2.4,30.4,2.9,29.9,3.3L29.9,3.3z"
          />
        </g>
      </g>
    </svg>
    <svg
      [ngClass]="{ 'coverage-banner__icon--active': loading || !data }"
      class="coverage-banner__icon coverage-banner__icon--loading"
      viewBox="0 0 20 20.5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="var(--tone-400)"
        d="M10 2.5c4.4 0 8 3.6 8 8s-3.6 8-8 8 -8-3.6-8-8S5.6 2.5 10 2.5M10 0.5c-5.5 0-10 4.5-10 10s4.5 10 10 10c5.5 0 10-4.5 10-10S15.5 0.5 10 0.5L10 0.5z"
      />
      <path
        fill="var(--tone-600)"
        d="M10 3c2.1 0 3.9 0.8 5.3 2.2l2.1-2.1C15.5 1.2 12.9 0 10 0 7.1 0 4.5 1.2 2.6 3.1L4.7 5.2C6.1 3.8 7.9 3 10 3z"
      />
    </svg>
  </div>
</ng-template>
