import { Component, Input } from '@angular/core';
import { toggleExpandedAnimation } from '../animations';

@Component({
  selector: 'ui-panel-summary',
  templateUrl: './panel-summary.component.html',
  styleUrls: ['./panel-summary.component.scss'],
  animations: [toggleExpandedAnimation],
})
export class PanelSummaryComponent {
  @Input() isExpanded = false;
}
