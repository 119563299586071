<div class="radio-list-container">
  <ng-container *ngFor="let item of list; let i = index">
    <ui-radio-list-item
      [label]="item.label"
      [desc]="item.desc"
      [subDesc]="item?.subDesc"
      [value]="item.value"
      [selected]="value === item.value"
      (valueChange)="onChange(item.value)"
    >
    </ui-radio-list-item>
  </ng-container>
</div>
