import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sales-why-get-protected',
  templateUrl: './why-get-protected.component.html',
  styleUrls: ['./why-get-protected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhyGetProtectedComponent {
  @Input() set clientName(clientName: string | null | undefined) {
    if (clientName) {
      this.client = clientName;
    }
  }

  client = 'Domestic and General';
}
