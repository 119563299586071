import { Component, EventEmitter, Input, Output } from '@angular/core';

export type RadioListItemValue = number | string | null;

@Component({
  selector: 'ui-radio-list-item',
  templateUrl: './radio-list-item.component.html',
  styleUrls: ['./radio-list-item.component.scss'],
})
export class RadioListItemComponent {
  @Input() label = '';
  @Input() desc = '';
  @Input() subDesc: string | undefined;
  @Input() value: RadioListItemValue = null;
  @Input() selected = false;
  @Output() valueChange: EventEmitter<RadioListItemValue> = new EventEmitter();

  onChange(value: RadioListItemValue) {
    this.valueChange.emit(value);
  }
}
