import { Component, Input, OnChanges } from '@angular/core';

interface Step {
  label: string;
  routes: string[];
}

@Component({
  selector: 'ui-progress-tracker-route-based',
  templateUrl: './progress-tracker-route-based.component.html',
})
export class ProgressTrackerRouteBasedComponent implements OnChanges {
  @Input() steps: Step[] | undefined | null;
  @Input() currentRoute = '';
  stepLabel: string[] = [];
  activeStep = -1;

  ngOnChanges() {
    this.generateStepLabel();
    this.activeStep = this.populateActiveStep();
  }

  generateStepLabel() {
    if (this.steps?.length) {
      this.stepLabel = this.steps.map((step) => step.label);
    }
  }

  populateActiveStep() {
    if (!this.steps?.length) {
      return -1;
    }
    return this.steps.findIndex((step) =>
      step.routes.includes(this.currentRoute)
    );
  }
}
