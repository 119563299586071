import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

type FocusableElement =
  | HTMLAnchorElement
  | HTMLButtonElement
  | HTMLInputElement
  | HTMLTextAreaElement;

/**
 * todo: improve aria (labeled by/described by etc.)
 */
@Component({
  selector: 'ui-inline-modal',
  templateUrl: './inline-modal.component.html',
  styleUrls: ['./inline-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineModalComponent implements OnInit, AfterViewInit {
  public open = false;
  @Input() initiallyOpen = false;
  @Input() modalCls = '';
  @ViewChild('modal') private modalRef!: ElementRef<HTMLElement>;

  @HostListener(':keydown.escape')
  private handleEscapeEvent() {
    this.hide();
  }

  constructor(private cd: ChangeDetectorRef) {}

  public show() {
    this.open = true;
    this.modalRef.nativeElement.focus();
    this.cd.detectChanges();
  }

  public hide() {
    this.open = false;
    this.cd.detectChanges();
  }

  public ngOnInit(): void {
    this.open = this.initiallyOpen;
  }

  public handleOverlayClick($event: MouseEvent) {
    if ($event.target === $event.currentTarget) {
      $event.preventDefault();
      this.hide();
    }
  }

  ngAfterViewInit() {
    this.trapFocus(this.modalRef.nativeElement);
    if (this.open) {
      this.modalRef.nativeElement.focus();
    }
  }

  //basic focus trap
  private trapFocus(element: HTMLElement) {
    const focusableElsQuery = element.querySelectorAll<FocusableElement>(
      'a[href], button, textarea, input[type="text"],' +
        'input[type="radio"], input[type="checkbox"], select'
    );
    const focusableEls = Array.from(focusableElsQuery).filter(
      (el: FocusableElement) => !(el as HTMLButtonElement).disabled
    );
    const firstFocusableEl: FocusableElement = focusableEls[0];
    const lastFocusableEl: FocusableElement =
      focusableEls[focusableEls.length - 1];

    element.addEventListener('keydown', function (e) {
      const isTabPressed = e.key === 'Tab';
      if (!isTabPressed) return;

      if (e.shiftKey) {
        /* shift + tab */
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else {
        /* tab */
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
    });
  }
}
