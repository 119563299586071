<footer>
  <div class="footer__subsection links__wrapper">
    <div class="container md-row sm-col links">
      <div class="links__group xs-12 md-6 lg-3">
        <h4>Products we protect</h4>
        <ul>
          <li *ngFor="let link of protectLinks">
            <ng-container
              [ngTemplateOutlet]="footerNavigationLink"
              [ngTemplateOutletContext]="{
                navigationLink: link
              }"
            ></ng-container>
          </li>
        </ul>
      </div>
      <div class="links__group xs-12 md-6 lg-3">
        <h4>More from us</h4>
        <ul>
          <li *ngFor="let link of moreLinks">
            <ng-container
              [ngTemplateOutlet]="footerNavigationLink"
              [ngTemplateOutletContext]="{
                navigationLink: link
              }"
            ></ng-container>
          </li>
        </ul>
      </div>
      <div class="links__group xs-12 md-6 lg-3">
        <h4>Useful links</h4>
        <ul>
          <li *ngFor="let link of usefulLinks">
            <ng-container
              [ngTemplateOutlet]="footerNavigationLink"
              [ngTemplateOutletContext]="{
                navigationLink: link
              }"
            ></ng-container>
          </li>
        </ul>
      </div>
      <div class="links__group xs-12 md-6 lg-3">
        <h4>Legal</h4>
        <ul>
          <li *ngFor="let link of legalLinks">
            <ng-container
              [ngTemplateOutlet]="footerNavigationLink"
              [ngTemplateOutletContext]="{
                navigationLink: link
              }"
            ></ng-container>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="disclaimers footer__subsection">
    <div class="container">
      <p>
        Service plans, maintenance & support plans are provided by Domestic &
        General Services Limited. Insurance policies are provided by Domestic &
        General Insurance PLC. Domestic & General Insurance PLC is an insurance
        undertaking, not an intermediary. We are the underwriter of the
        insurance policies and do not provide a personal recommendation or
        advice. Domestic & General Insurance PLC is authorised by the Prudential
        Regulation Authority and regulated by the Financial Conduct Authority
        and the Prudential Regulation Authority (Financial Services Register
        Number 202111). Our address and details of our authorisation can be
        checked on the FCA website or by contacting the FCA on 0800 111 6768.
      </p>
      <p>
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy" target="_blank"
          >Privacy Policy</a
        >
        and
        <a href="https://policies.google.com/terms" target="_blank"
          >Terms of Service</a
        >
        apply.
      </p>
    </div>
  </div>
  <div class="container">
    <div
      class="bottom footer__subsection md-row xs--stack xs--middle sm--middle"
    >
      <div>
        <p>&copy; {{ currentYear }} Domestic & General Group Limited</p>
      </div>
      <ul class="footer__social">
        <li>
          <a
            href="https://www.facebook.com/DandGUK/"
            class="icon icon--xl icon-facebook footer__social-link"
            aria-label="Domestic and General's Facebook profile (opens in new window)"
            target="_blank"
          ></a>
        </li>
        <li>
          <a
            href="https://twitter.com/DandG_UK"
            class="icon icon--xl icon-twitter footer__social-link"
            aria-label="Domestic and General's Twitter profile (opens in new window)"
            target="_blank"
          ></a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/domesticandgeneral/"
            class="icon icon--xl icon-instagram footer__social-link"
            aria-label="Domestic and General's Instagram profile (opens in new window)"
            target="_blank"
          ></a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/domestic-&-general/mycompany/"
            class="icon icon--xl icon-linkedin footer__social-link"
            aria-label="Domestic and General's LinkedIn profile (opens in new window)"
            target="_blank"
          ></a>
        </li>
      </ul>
    </div>
  </div>
</footer>

<ng-template #footerNavigationLink let-navigationLink="navigationLink">
  <a
    *ngIf="navigationLink.routerPath; else externalFooterNavigationLink"
    [routerLink]="[navigationLink.routerPath]"
  >
    {{ navigationLink.label }}
  </a>

  <ng-template #externalFooterNavigationLink>
    <a [href]="navigationLink.href" target="_blank">
      {{ navigationLink.label }}
    </a>
  </ng-template>
</ng-template>
