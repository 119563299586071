<footer>
  <div class="footer__subsection links__wrapper">
    <div class="container md-row sm-col links">
      <div class="links__group xs-12 md-6">
        <h4>Useful links</h4>
        <ul>
          <li *ngFor="let link of usefulLinks">
            <ng-container
              [ngTemplateOutlet]="footerNavigationLink"
              [ngTemplateOutletContext]="{
                navigationLink: link
              }"
            ></ng-container>
          </li>
        </ul>
      </div>
      <div class="links__group xs-12 md-6">
        <h4>Legal</h4>
        <ul>
          <li *ngFor="let link of legalLinks">
            <ng-container
              [ngTemplateOutlet]="footerNavigationLink"
              [ngTemplateOutletContext]="{
                navigationLink: link
              }"
            ></ng-container>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="disclaimers footer__subsection">
    <div class="container">
      <p>
        EDF is a trading name used by EDF Energy Customers Ltd. Reg. No.
        02228297. Registered office is 90 Whitfield Street, London, W1T 4EZ,
        incorporated in England and Wales. EDF is an Appointed Representative of
        Domestic & General Insurance PLC, which is authorised by the Prudential
        Regulation Authority and regulated by the Financial Conduct Authority
        and the Prudential Regulation Authority. BoilerCare Value and BoilerCare
        Total policies are provided by Domestic & General Insurance PLC
        Registered Office: Swan Court, 11 Worple Road, Wimbledon, London SW19
        4JS, United Kingdom. Registered in England and Wales, Company No.
        485850. Domestic & General Insurance PLC is authorised by the Prudential
        Regulation Authority and regulated by the Financial Conduct Authority
        and the Prudential Regulation Authority. Domestic & General Insurance
        PLC is an insurance undertaking, not an intermediary. They are the
        underwriter of the insurance and do not provide a personal
        recommendation. Full details on how to submit a complaint can be found
        in the terms and Conditions. Domestic & General Insurance PLC pay their
        sales agents periodic incentives (such as bonus payments) if they meet
        certain quality and sales targets. Maintenance and Support Plans
        (BoilerCare Gold, BoilerCare Platinum) are provided by Domestic &
        General Services Limited. Registered in England and Wales. Company No.
        1970780. Registered office: Swan Court, 11 Worple Road, Wimbledon,
        London SW19 4JS.
      </p>
      <p>
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy" target="_blank">
          Privacy Policy
        </a>
        and
        <a href="https://policies.google.com/terms" target="_blank">
          Terms of Service
        </a>
        apply.
      </p>
    </div>
  </div>
  <div class="container">
    <div
      class="bottom footer__subsection md-row xs--stack xs--middle sm--middle"
    >
      <div>
        <p>&copy; {{ currentYear }} Domestic & General Group Limited</p>
      </div>
      <ul class="footer__social">
        <li>
          <a
            href="https://www.facebook.com/DandGUK/"
            class="icon icon--xl icon-facebook footer__social-link"
            aria-label="Domestic and General's Facebook profile (opens in new window)"
            target="_blank"
          ></a>
        </li>
        <li>
          <a
            href="https://twitter.com/DandG_UK"
            class="icon icon--xl icon-twitter footer__social-link"
            aria-label="Domestic and General's Twitter profile (opens in new window)"
            target="_blank"
          ></a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/domesticandgeneral/"
            class="icon icon--xl icon-instagram footer__social-link"
            aria-label="Domestic and General's Instagram profile (opens in new window)"
            target="_blank"
          ></a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/domestic-&-general/mycompany/"
            class="icon icon--xl icon-linkedin footer__social-link"
            aria-label="Domestic and General's LinkedIn profile (opens in new window)"
            target="_blank"
          ></a>
        </li>
      </ul>
    </div>
  </div>
</footer>

<ng-template #footerNavigationLink let-navigationLink="navigationLink">
  <a
    *ngIf="navigationLink.routerPath; else externalFooterNavigationLink"
    [routerLink]="[navigationLink.routerPath]"
  >
    {{ navigationLink.label }}
  </a>

  <ng-template #externalFooterNavigationLink>
    <a [href]="navigationLink.href" target="_blank">
      {{ navigationLink.label }}
    </a>
  </ng-template>
</ng-template>
