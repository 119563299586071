import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { SelectPaymentFacade } from '../../+state/select-payment.facade';
import { BuildConfigService } from '@common/util-foundation';
import { PaymentType } from '@common/util-models';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

@Injectable()
export class SelectedPaymentTypeGuard implements CanActivate {
  constructor(
    private buildConfigService: BuildConfigService,
    private selectPaymentFacade: SelectPaymentFacade,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.selectPaymentFacade.selectedPayment$.pipe(
      first(),
      map((selectedPaymentType: PaymentType | undefined) => {
        return selectedPaymentType === route?.data?.paymentType;
      }),
      tap((canActivate: boolean) => {
        if (!canActivate) {
          this.router.navigateByUrl(
            this.buildConfigService.config.selectPaymentPage
          );
        }
      })
    );
  }
}
