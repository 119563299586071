import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'ui-form-validation-icon',
  template: '',
  styleUrls: ['./validation-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationIconComponent {
  @Input() set validityStatus(value: string) {
    this.validityCls = value ? `is-${value}` : '';
  }
  @HostBinding('class') private validityCls = '';
}
