import { AlertDialogConfig } from '../models/alert-dialog-config.interface';

export const DefaultAlertErrorDialogConfig: AlertDialogConfig = {
  data: {
    title: 'Sorry, we lost you there.',
    body: [
      'It looks like there’s been a technical issue but we’re working to fix it.',
    ],
    primaryCta: {
      text: 'Close and try again',
    },
  },
};
