import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BuildConfigService } from '@common/util-foundation';
import { BuildConfig } from '@common/util-models';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'sales-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
  config: BuildConfig = this.buildConfigService.config;
  protectLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'protect'
  );
  moreLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'more'
  );
  usefulLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'useful'
  );
  legalLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'legal'
  );

  constructor(private buildConfigService: BuildConfigService) {}
}
