import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { QuoteParamsParserService } from '@common/util-foundation';
import {
  ApplianceDetailsFormDataProvider,
  ApplianceDetailsQueryParamsForm,
  ApplianceFormField,
  ApplianceFormSubmit,
  ParseResponse,
  ParseResult,
} from '@common/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ApplianceDetailsQueryParamsFormDataProvider
  implements ApplianceDetailsFormDataProvider {
  priority = 0;
  private readonly validators = [Validators.required];
  private readonly priceValidators = [
    ...this.validators,
    Validators.minLength(2),
    Validators.maxLength(4),
  ];
  private readonly yesNoValidators = [
    ...this.validators,
    Validators.pattern(/^(yes|no)$/i),
  ];
  private readonly yearStringValidators = [
    ...this.validators,
    Validators.minLength(4),
    Validators.maxLength(4),
  ];
  private readonly monthStringValidators = [
    ...this.validators,
    Validators.minLength(1),
    Validators.maxLength(2),
  ];

  constructor(
    private quoteParamsParserService: QuoteParamsParserService<ApplianceDetailsQueryParamsForm>
  ) {}

  getApplianceDetailsForm(): Observable<ParseResponse<ApplianceFormSubmit>> {
    const applianceDetailsForm$ = this.quoteParamsParserService.parse({
      appliance: this.validators,
      brand: this.validators,
      applianceCode: this.validators,
      brandCode: this.validators,
      purchasePrice: this.priceValidators,
      goodWorkingOrder: this.yesNoValidators,
      underGuarantee: this.yesNoValidators,
      month: this.monthStringValidators,
      year: this.yearStringValidators,
      applianceCategory: [],
      codeItem: [],
    });

    return applianceDetailsForm$.pipe(
      map((applianceDetailsParsed) =>
        this.castApplianceForm(applianceDetailsParsed)
      )
    );
  }

  private castApplianceForm(
    parsedResponse: ParseResponse<ApplianceDetailsQueryParamsForm>
  ): ParseResponse<ApplianceFormSubmit> {
    const applianceFormSubmit = this.createFormSubmit(parsedResponse);
    parsedResponse.model = applianceFormSubmit;
    parsedResponse = this.adaptFormGroup(parsedResponse, applianceFormSubmit);
    return parsedResponse;
  }

  createFormSubmit(
    parsedResponse: ParseResponse<ApplianceDetailsQueryParamsForm>
  ): ApplianceFormSubmit {
    const parsedResponseModel = parsedResponse.model;
    const formFieldList = [
      'applianceCode',
      'brandCode',
      ApplianceFormField.Appliance,
      ApplianceFormField.Brand,
      ApplianceFormField.GoodWorkingOrder,
      ApplianceFormField.PurchasePrice,
      ApplianceFormField.UnderGuarantee,
    ];
    const applianceFormSubmit = formFieldList.reduce(
      (acc, curr) => {
        const formField = this.getValuesWhenFormFieldIsValid(
          parsedResponse,
          curr
        );
        return {
          ...acc,
          [curr]: formField,
        };
      },
      {
        monthYearDate: {
          month: parsedResponse.form?.get('month')?.valid
            ? Number(parsedResponseModel?.month)
            : 0,
          year: parsedResponse.form?.get('year')?.valid
            ? Number(parsedResponseModel?.year)
            : 0,
        },
      }
    );
    return applianceFormSubmit as ApplianceFormSubmit;
  }

  private getValuesWhenFormFieldIsValid(
    parsedResponse: ParseResponse<ApplianceDetailsQueryParamsForm>,
    formField: string
  ): string {
    return parsedResponse.form?.get(formField)?.value &&
      parsedResponse.form?.get(formField)?.valid
      ? parsedResponse.form?.get(formField)?.value
      : '';
  }

  adaptFormGroup(
    parsedResponse: ParseResponse<ApplianceDetailsQueryParamsForm>,
    applianceFormSubmit: ApplianceFormSubmit
  ) {
    parsedResponse.form?.addControl(
      ApplianceFormField.PurchaseDate,
      new FormControl(
        {
          month: applianceFormSubmit.monthYearDate.month,
          year: applianceFormSubmit.monthYearDate.year,
        },
        [...this.validators, this.numberDateValidator]
      )
    );
    parsedResponse.form?.removeControl('year');
    parsedResponse.form?.removeControl('month');
    parsedResponse.form?.removeControl('codeItem');
    if (parsedResponse.form?.invalid) {
      parsedResponse.result = ParseResult.Failure;
    }
    return parsedResponse;
  }

  private numberDateValidator(control: FormControl) {
    if (control.value.month < 1 || control.value.month > 12) {
      return {
        invalidMonth: true,
      };
    }
    if (
      control.value.year < 2000 ||
      control.value.year > new Date().getFullYear()
    ) {
      return {
        invalidYear: true,
      };
    }
    return null;
  }
}
