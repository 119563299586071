<ui-panel
  (expandedChange)="handleExpandedChange($event)"
  [initialExpanded]="!quoteSummaryConfig?.isForSmallScreen"
  [sticky]="!!quoteSummaryConfig?.isForSmallScreen && 'bottom'"
  class="quote-summary"
>
  <ui-panel-header *ngIf="title || quoteSummaryConfig?.isForSmallScreen">
    <ui-panel-title variant="highlight">
      {{ title }}
    </ui-panel-title>
    <ui-panel-toggle-button
      *ngIf="quoteSummaryConfig?.isForSmallScreen"
    ></ui-panel-toggle-button>
  </ui-panel-header>

  <ui-panel-body>
    <p *ngIf="!quotes?.length" class="quote-summary__empty">0 items</p>
    <wl-quote-summary-item
      *ngFor="let quote of quotes; trackBy: trackQuote"
      (multiItemDelete)="handleMultiItemDelete($event)"
      [applianceIsWhiteGoods]="applianceIsWhiteGoods"
      [isMultiItem]="isMultiItem"
      [isFixedTerm]="isFixedTerm"
      [last]="quotes?.length === 1"
      [multiItemDeleteEnabled]="!!multiItemDelete.observers.length"
      [quote]="quote"
      [selectedPaymentTypeIsCard]="selectedPaymentTypeIsCard"
    ></wl-quote-summary-item>

    <ng-container [ngTemplateOutlet]="quoteTotal"></ng-container>

    <div
      *ngIf="!quoteSummaryConfig?.hideTrustMarks && !!quotes.length"
      class="quote-summary__footer"
    >
      <p class="quote-summary__footer-title">Fast & secure payment</p>
      <wl-payment-icons
        [showDirectDebitIcon]="isDDPaymentAvailable"
        [showCardIcons]="
          (!isMultiItem || selectedPaymentTypeIsCard) && isCardPaymentAvailable
        "
      ></wl-payment-icons>
    </div>
  </ui-panel-body>

  <ui-panel-summary>
    <ng-container [ngTemplateOutlet]="quoteTotal"></ng-container>
  </ui-panel-summary>
</ui-panel>

<ng-template #quoteTotal>
  <wl-quote-summary-total
    [applianceIsWhiteGoods]="applianceIsWhiteGoods"
    [isExpanded]="isExpanded"
    [isFixedTerm]="isFixedTerm"
    [isMultiItem]="isMultiItem"
    [quotes]="quotes"
    [selectedPaymentTypeIsCard]="selectedPaymentTypeIsCard"
    [storeDiscountPercentage]="quoteSummaryConfig?.discountPercentage"
  ></wl-quote-summary-total>
</ng-template>
